/* Basic attribute classes */
/* @import url("https://fonts.googleapis.com/css?family=Open+Sans:100,200,300,300i,400,400i,600,600i,700,700i"); */
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");

h1,
h2,
h3,
h4,
h5,
h6,
label {
  font-family: "Montserrat", sans-serif;
}

body {
  font-family: "Roboto", sans-serif;
  color: #484848;
}

.max-w-90 {
  max-width: 90%;
}

.purpleText {
  color: #00265b;
}

.pointer {
  cursor: pointer;
}

.grab {
  cursor: grab;
}

.margin10 {
  margin: 10px 0;
}

.padding10 {
  padding: 10px;
}

.spaceBetween {
  display: flex;
  justify-content: space-between;
}

.smallerText {
  font-size: 0.8rem;
  margin: 0;
  font-weight: 500;
}

.purpleText {
  color: #00265b;
}

p {
  color: #484848;
}

li {
  color: #484848;
  font-weight: 300;
}

svg {
  color: #484848;
}

.hMax {
  margin: 4px 0;
  font-size: 2rem !important;
  color: #ffffff;
  text-align: center;
  font-weight: 200;
}

.hMax strong {
  font-weight: 600;
}

h2 strong,
h3 strong,
h4 strong,
h5 strong,
h6 strong {
  font-weight: 700 !important;
}

h1 {
  margin: 4px 0;
  font-size: 1.75rem !important;
  color: #484848;
  font-weight: 700;
}

h2 {
  margin: 4px 0;
  font-size: 1.5rem !important;
  color: #484848;
}

h3 {
  margin: 0;
  font-size: 1.25rem !important;
  color: #484848;
}

h4 {
  margin: 0;
  font-size: 1rem !important;
  color: #484848;
  line-height: 1.4 !important;
}

h5 {
  margin: 0;
  font-size: 0.75rem !important;
  color: #484848;
}

p {
  font-weight: 300;
}

p strong {
  font-weight: 600;
}

.font-label-strong strong {
  font-weight: 600;
}

.stdEmptySpace {
  height: 30px;
  width: 100%;
}

.numberCircle {
  border-radius: 100%;
  border: 4px #455a64 solid;
  display: inline-block;
  height: 45px;
  width: 45px;
}

.numberCircle h3 {
  text-align: center;
  margin: 0 !important;
  line-height: 37px;
  font-weight: 600;
}

/* End basic attribute classes */

/* Commonly Used Components */

/* Containers */

.mainHeader {
  width: 100%;
  font-family: "Montserrat", sans-serif;
  background: linear-gradient(
    90deg,
    rgba(0, 39, 91, 1) 0%,
    rgba(0, 199, 248, 1) 100%
  );
  color: #ffffff !important;
  height: 50px;
  padding: 0 40px !important;
  margin-bottom: 10px;
}

.mainHeader .role-label {
  margin-top: -0.1rem;
  color: white;
  max-width: 15rem;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.mainHeader .role-label > em {
  color: #ffab00;
  font-style: normal;
}

.mainHeaderUserOptions {
  color: #ffffff;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.mainHeaderUserOptions img {
  border: 2px #cfd8dc solid;
  margin-left: 10px;
}

.mainHeaderUserOptions svg {
  color: #ffffff;
  font-size: 1.5rem;
}

.user-menu-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-menu-item:hover {
  cursor: pointer;
  color: #00275b;
}

.user-actions-popover > .popover-body {
  padding: 0;
}

.user-actions-popover .list-group-item {
  padding: 0.25rem 1.25rem;
  margin: unset;
  font-weight: bold;
}

.lightBlueContainer {
  background-color: #f0f4f7 !important;
  height: 190px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inviteContainer,
.interventionDetailsContainer {
  background-color: #f7f4fc !important;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
}

.sectionContainer {
  background-color: #f4f4f4 !important;
  padding: 15px;
  margin: 10px 0;
  border-radius: 4px;
}

.loginContainer {
  background: linear-gradient(
    90deg,
    rgba(0, 39, 91, 1) 0%,
    rgba(0, 199, 248, 1) 100%
  );
  /*background-color: #00265b;*/
  padding: 50px 40px !important;
  border: 15px #ffffff solid;
  border-radius: 12px;
  box-shadow: 1px 3px rgba(240, 240, 240, 0.3);
  margin-top: 43px;
}

.welcomeInputContainer {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 30px 40px;
  box-shadow: 1px 3px rgba(32, 32, 32, 0.3);
}

/*.welcomeInputContainer .form-group {*/
/*  !*margin-bottom: 30px !important;*!*/
/*}*/

.welcomeInputContainer h5 {
  font-size: 0.8rem !important;
  margin-bottom: 30px;
}

.contentContainer {
  width: 100% !important;
  padding: 0 50px !important;
  margin: auto;
  max-width: unset !important;
}

.colorPickerModal {
  text-align: center;
  width: 100% !important;
}

.colorPickerDialog {
  max-width: 990px !important;
}

.praisePickerDialog {
  margin-top: 0;
  margin-bottom: 0;
  max-width: 700px !important;
}

.praisePickerDialog .modal-content {
  box-shadow: 0px 0px 20px 5px #90a4ae;
  border-radius: 4px;
}

.classSetupContainerEmpty {
  background: #f0f4f7;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.28);
  text-align: center;
  border-radius: 8px;
  padding: 120px 80px;
  margin-top: 10px;
}

.schoolSelect {
  font-size: 0.875rem;
  margin-bottom: 20px;
}

.schoolSelect > div {
  border-color: #cfd8dc !important;
}

.targetBehaviorContainer {
  height: 100%;
  background: #f0f4f7;
  border-radius: 4px;
  padding: 10px;
}

.targetBehaviorContainer p {
  display: inline;
  font-weight: 600;
  font-size: 0.9rem;
}

.setGoalConfirmationBanner {
  /*padding: 2.2rem 3rem;*/
  text-align: center;
  color: #ffffff;
  font-weight: 700 !important;
  background-color: #4cb5ab;
  padding: 5px;
}

/* Line break styling */

.wideMargin {
  margin: 30px 0 !important;
}

.narrowMargin {
  margin: 5px 0 !important;
}

/* Button Styling */

.purpleBtnMd {
  background-color: rgb(255, 171, 0);
  border-style: solid;
  border-color: #00265b;
  border-radius: 30px !important;
  border-width: 1px;
  padding: 10px 20px !important;
  color: #00265b;
  font-weight: 700 !important;
  font-size: 1rem !important;
  font-family: "Roboto", sans-serif;
  text-transform: capitalize;
  text-align: center;
  cursor: pointer;
}

.purpleBtnMd svg {
  margin-right: 10px;
  color: #00265b;
}

.purpleSolidBtnMd {
  background-color: #00265b;
  border-style: solid;
  border-color: #00265b;
  border-radius: 30px !important;
  padding: 10px 20px !important;
  margin: 5px 0 !important;
  color: #ffffff;
  font-weight: 700 !important;
  font-size: 1rem !important;
  font-family: "Roboto", sans-serif;
  text-transform: capitalize;
  text-align: center;
  cursor: pointer;
  min-width: 350px;
}

.purpleRegularBtnMd {
  background-color: #ffffff;
  border-style: solid;
  border-color: #00265b;
  border-radius: 30px !important;
  padding: 10px 20px !important;
  margin: 5px 0 !important;
  color: #00265b;
  font-weight: 700 !important;
  font-size: 1rem !important;
  font-family: "Roboto", sans-serif;
  text-transform: capitalize;
  text-align: center;
  cursor: pointer;
  min-width: 350px;
}

.boxButton {
  background: #f7f5fb;
  border-radius: 8px;
  padding: 15px;

  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}

.columnContainer {
  width: 100%;
}

.columnContainer > div {
  margin-bottom: 20px;
}

.boxButton svg {
  margin-right: 10px;
}

.flexContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column wrap;
  align-content: center;
}

.showFiltersButton {
  margin: 5px 10px !important;
  cursor: pointer;
}

.showFiltersButton svg {
  color: #00265b !important;
}

.whiteActionBtn {
  color: #536e7b;
  background-color: #ffffff;
  border: 1px solid rgba(211, 222, 236, 0.88);
  box-shadow: 1px 1px 2px 0 #90a4ae;
  border-radius: 4px;
  padding: 10px 20px;
  font-weight: 600;
  font-size: 0.9rem;
}

.whiteActionBtn:hover {
  border: 1px solid rgba(211, 222, 236, 0.4);
  box-shadow: 1px 1px 1px 0 #90a4ae;
  transition: box-shadow 0.4s;
}

.blueBtnXL {
  border: none;
  background-color: #00265b;
  border-radius: 8px !important;
  padding: 20px 60px !important;
  color: #f5f5f5 !important;
  font-weight: 600 !important;
  font-size: 1rem !important;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
}

.blueBtnLg {
  border: none;
  background-color: #00265b;
  border-radius: 8px !important;
  padding: 15px 60px !important;
  color: #f5f5f5 !important;
  font-weight: 600 !important;
  font-size: 0.9rem !important;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
}

.blueBtnMd {
  border: none;
  background-color: #00265b;
  border-radius: 6px !important;
  padding: 10px 30px !important;
  color: #f5f5f5;
  font-weight: 600 !important;
  font-size: 0.9rem !important;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  display: inline-block;
}

.blueBtnMdFull {
  border: none;
  background-color: #00265b;
  border-radius: 6px !important;
  padding: 10px 30px !important;
  color: #f5f5f5;
  font-weight: 600 !important;
  font-size: 0.9rem !important;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  width: 100%;
}

.blueBtnSmXs {
  border: none;
  background-color: #00265b;
  border-radius: 4px !important;
  padding: 5px 15px !important;
  color: #ffffff;
  font-weight: 600 !important;
  font-size: 0.7rem;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
}

.blueBtnSm {
  border: none;
  background-color: #00265b;
  border-radius: 4px !important;
  padding: 5px 15px !important;
  color: #ffffff;
  font-weight: 600 !important;
  font-size: 0.9rem;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
}

.blueBtnSmFull {
  background-color: #00265b;
  border-radius: 4px !important;
  padding: 5px 15px !important;
  color: #ffffff;
  font-weight: 600 !important;
  font-size: 0.9rem !important;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  width: 100%;
}

.btnDisabled {
  background-color: #b0bec5 !important;
}

.blueBtnXL:hover,
.blueBtnLg:hover,
.blueBtnMd:hover,
.blueBtnSm:hover,
.blueBtnSmXs:hover,
.blueBtnLg:focus,
.blueBtnMd:focus,
.blueBtnSm:focus,
.blueBtnSmXs:focus {
  background-color: #2547a5;
  transition: background-color 0.4s;
  outline: none;
}

.blueBtnSmXs:hover,
.blueBtnSmXs:focus {
  font-size: 0.7rem;
}

.blueBtnLg:disabled,
.blueBtnMd:disabled,
.blueBtnSm:disabled,
.blueBtnSmXs:disabled {
  background-color: #b0bec5 !important;
  cursor: not-allowed;
  pointer-events: all !important;
}

.blueBtnSm.disabledWithPopover {
  pointer-events: none !important;
}

.greyCircleBtn {
  background-color: #9b9b9b;
  color: #ffffff;
  height: 16px;
  width: 16px;
  border-radius: 8px;
  line-height: 16px;
  font-size: 0.5rem;
  cursor: pointer;
  font-weight: 600;
}

.whiteBtnSm {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  color: #536e7b;
  background-color: #ffffff;
  border: 1px solid rgba(211, 222, 236, 0.88);
  box-shadow: 1px 1px 2px 0 #90a4ae;
  border-radius: 4px;
  padding: 5px 15px;
  font-weight: 600;
  font-size: 0.8rem;
}

.whiteBtnSmXs {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  color: #536e7b;
  background-color: #ffffff;
  border: 1px solid rgba(211, 222, 236, 0.88);
  box-shadow: 1px 1px 2px 0 #90a4ae;
  border-radius: 4px;
  padding: 5px 15px;
  font-weight: 600;
  font-size: 0.6rem;
}

.whiteBtnMd2 {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  color: #536e7b;
  background-color: #ffffff;
  border: 1px solid rgba(211, 222, 236, 0.88);
  box-shadow: 1px 1px 2px 0 #90a4ae;
  border-radius: 4px;
  padding: 10px 30px;
  font-weight: 600;
  font-size: 0.9rem;
}

.whiteBtnMd {
  flex: 1;
  background-color: #ffffff;
  box-shadow: 0px 2px 6px rgba(32, 32, 32, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  padding: 10px 20px;
  color: #455a64;
  text-align: center;
  cursor: pointer;
}

.whiteBtnXL2 {
  border: none;
  background-color: #ffffff;
  border-radius: 8px !important;
  padding: 15px 40px !important;
  color: #484848 !important;
  font-weight: bold !important;
  font-size: 1.25rem !important;
  font-family: "Roboto", sans-serif;
  text-transform: capitalize;
  text-align: center;
  cursor: pointer;
}

.collectEvidenceEmptyContainerBtns .whiteBtnMd:first-of-type,
.collectEvidenceEmptyContainerBtns .whiteBtnMd:last-of-type {
  margin: 0 !important;
}

.collectEvidenceEmptyContainerBtns .whiteBtnMd:nth-of-type(2) {
  margin: 0 20px !important;
}

.whiteBtnXL {
  flex: 1;
  min-height: 220px;
  max-width: 325px;
  margin: 5px 10px;
  background-color: #ffffff;
  box-shadow: 0px 2px 6px rgba(32, 32, 32, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 12px;
  padding: 40px 20px;
  color: #455a64;
  text-align: center;
  cursor: pointer;
}

.whiteBtnSm:hover,
.whiteBtnMd:hover,
.whiteBtnXL:hover,
.whiteBtnXL2:hover
 {
  background: #fcfcfc; /* Old browsers */
  background: -moz-linear-gradient(
    180deg,
    #ffffff 40%,
    #fcfcfc 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    180deg,
    #ffffff 40%,
    #fcfcfc 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    180deg,
    #ffffff 40%,
    #fcfcfc 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  box-shadow: 0px 2px 6px rgba(32, 32, 32, 0.2);
  transition: box-shadow 0.4s;
}

.whiteBtnMd,
.whiteBtnXL svg {
  margin-bottom: 10px;
}

.whiteBtnMdTitle,
.whiteBtnXLTitle {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
  padding-left: 5px;
}

.whiteBtnXLText {
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
}

.leftActions .BlueBtnMd,
.leftActions .BlueBtnLg,
.leftActions .BlueBtnSm {
  margin-right: 10px;
}

.rightActions .BlueBtnMd,
.rightActions .BlueBtnLg,
.rightActions .BlueBtnSm {
  margin-left: 10px;
}

.clickableLabel {
  text-decoration: underline;
  cursor: pointer;
  color: #455a64;
}

.clickableLabelBlue {
  cursor: pointer;
  color: #00265b !important;
}

.clickableLabelWhite {
  text-decoration: underline;
  cursor: pointer;
  color: #ffffff !important;
}

.btnModalBack {
  border: none;
  background-color: inherit;
  color: #ffffff;
  font-weight: 600 !important;
  font-size: 1.2rem !important;
  font-family: "Roboto", sans-serif;
  text-transform: capitalize;
  text-align: center;
  cursor: pointer;
}

.btnModalBack > svg {
  font-size: 1.7rem !important;
  margin-right: 10px;
}

.marigoldBtnSm {
  border: none;
  background-color: rgb(255, 171, 0);
  border-radius: 4px !important;
  padding: 5px 15px !important;
  color: #ffffff;
  font-weight: 600 !important;
  font-size: 0.78rem;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  height: 27px;
}

.marigoldBtnMd {
  border: none;
  background-color: #ffab00;
  border-radius: 8px !important;
  padding: 10px 20px;
  color: #ffffff !important;
  font-weight: bold !important;
  font-size: 1.25rem !important;
  font-family: "Roboto", sans-serif;
  text-transform: capitalize;
  text-align: center;
  cursor: pointer;
}

.marigoldBtnXL {
  border: none;
  background-color: #ffab00;
  border-radius: 8px !important;
  padding: 15px 40px !important;
  color: #ffffff !important;
  font-weight: bold !important;
  font-size: 1.25rem !important;
  font-family: "Roboto", sans-serif;
  text-transform: capitalize;
  text-align: center;
  cursor: pointer;
}

.marigoldBtnSm:hover,
.marigoldBtnMd:hover,
.marigoldBtnXL:hover {
  background-color: #ff9100;
  transition: background-color 0.4s;
  outline: none;
  color: #0056b3 !important;
}

/* End Button Styling */

.defaultDropdownMenu {
  top: unset !important;
}

.whiteDropdown {
  background-color: #ffffff;
  border: 1px #d3deec solid;
  font-size: 0.75rem;
  font-weight: 600 !important;
  border-radius: 4px;
  color: #484848;
  box-shadow: 1px 1px 2px #90a4ae;
  display: inline-block;
}

.whiteDropdown button {
  padding: 5px 10px;
}

.whiteDropdown:focus {
  outline: none !important;
}

.dropdown > .whiteDropdown.btn-secondary {
  color: #484848 !important;
  background-color: #ffffff !important;
  border: 1px #d3deec solid;
  padding: 5px 10px;
}

.dropdown > .whiteDropdown:active,
.dropdown > .whiteDropdown:focus,
.dropdown > .whiteDropdown:hover {
  background-color: #ffffff !important;
  border: 1px #d3deec solid !important;
  color: #484848 !important;
}

.dropdownLg {
  background-color: #ffffff;
  border: 1px #d3deec solid;
  font-size: 0.875rem;
  font-weight: 600 !important;
  border-radius: 4px;
  color: #484848;
  box-shadow: 1px 1px 2px #90a4ae;
  display: inline-block;
  width: 100%;
}

.dropdownLg button {
  padding: 10px;
}

.dropdownLg:focus {
  outline: none !important;
}

.fullWidthInput {
  width: 100%;
  border-radius: 4px;
  border: 1px #cfd8dc solid;
  padding: 8px;
  color: #455a64;
  font-size: 0.875rem;
  outline: none;
  box-shadow: none;
}

.fullWidthTextarea {
  width: 100%;
  min-height: 100px;
  border-radius: 4px;
  border: 1px #cfd8dc solid;
  padding: 10px;
  color: #455a64;
  font-size: 0.875rem;
  outline: none;
  box-shadow: none;
}

.fullWidthInput::placeholder,
.fullWidthTextarea::placeholder,
.stdInput::placeholder,
.inputSm::placeholder {
  font-weight: 300;
  color: #c5c5c5;
}

.fullWidthInput:focus,
.fullWidthTextarea:focus,
.stdInput:focus,
.inputSm:focus,
.editableDataCellInput:focus {
  box-shadow: 0 0 5px rgba(46, 116, 209, 0.6);
  transition: box-shadow 0.4s;
}

.stdInput {
  height: 34px;
  border-radius: 4px;
  border: 1px #cfd8dc solid;
  padding: 10px;
  color: #455a64;
  font-size: 0.875rem;
  outline: none;
  box-shadow: none;
}

.inputSm {
  height: 34px;
  border-radius: 4px;
  border: 1px #cfd8dc solid;
  padding: 10px;
  color: #455a64;
  font-size: 0.875rem;
  outline: none;
  box-shadow: none;
  width: 50px !important;
  text-align: center;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.actionInfoContainer {
  background-color: #ffffff;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.actionInfoContainer > div > div:first-of-type {
  padding: 0 10px;
}

.actionInfoContainer > div > div:nth-of-type(2) {
  padding-right: 10px;
}

.actionInfoContainer svg {
  margin-right: 10px;
}

.assignmentsInfoContainer {
  background-color: #ffffff;
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
}

.interventionGroupGoalContainer {
  margin: 20px 20px 0 20px;
  border-radius: 12px;
}

.interventionGroupGoalInnerContainer {
  display: flex;
}

.interventionGroupGoalInnerContainer > div {
  flex: 1;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
}

.parentCell button {
  font-size: 11px;
  margin: 1px 0 !important;
  width: 100% !important;
}

@media screen and (min-width: 1201px) {
  .interventionGroupGoalInnerContainer > div:last-child {
    justify-content: center;
    border-left: 2px solid rgba(0, 0, 0, 0.1);
  }

  .interventionGroupGoalInnerContainer .interventionStudentsContainer {
    flex-basis: 50%;
  }

  .interventionGroupGoalInnerContainer > div:last-child .fidelityContainer {
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  .interventionTimeOfDayPanel {
    display: none !important;
  }

  .interventionGroupGoalInnerContainer {
    flex-direction: column;
  }

  .interventionGroupGoalInnerContainer > div:first-child {
    justify-content: space-between;
  }

  .interventionGroupGoalInnerContainer > div:last-child {
    justify-content: space-around;
  }

  .interventionGroupGoalInnerContainer > div:last-child .fidelityContainer {
    flex-grow: unset;
  }

  .interventionGroupGoalInnerContainer > div:last-child > div:only-child {
    margin: auto !important;
  }

  .interventionGroupGoalInnerContainer .studentGroupCell {
    flex-basis: unset;
  }

  .interventionGroupGoalInnerContainer > div:first-child > div:nth-child(1),
  .interventionGroupGoalInnerContainer > div:first-child > div:nth-child(3) {
    display: none !important;
  }

  .interventionInfoContainer {
    margin: auto !important;
  }
}

/*@media screen and (min-width: 1100px) and (max-width: 1280px) {*/
/*  .interventionGroupGoalInnerContainer > div:first-child {*/
/*    flex: 1;*/
/*  }*/
/*  .interventionGroupGoalInnerContainer > div:first-child > div:first-child {*/
/*    display: none !important;*/
/*  }*/
/*  .interventionGroupGoalInnerContainer > div:last-child {*/
/*    flex: 1;*/
/*  }*/
/*}*/

.customDarkPopoverBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
}

/* End Commonly Used Components */

/* Modal Styling */

.customDarkModalBackdrop {
  background-color: #000;
  opacity: 0.5;
}

.customDarkModalBackdrop.inner {
  z-index: 1050;
}

.customWhiteModalBackdrop {
  background-color: #ffffff;
  opacity: 0.5;
}

.smallAlertModal {
  background: rgba(255, 255, 255, 0.75);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  border-radius: 12px;

  display: flex;
  justify-content: center;
}

.smallAlertModal > div {
  background: #ffffff;
  padding: 20px;
  width: 400px;
  height: fit-content;
  margin-top: 20%;
  box-shadow: 0 0 2px 4px rgba(176, 190, 197, 0.4);
}

.confirmDialog {
  background: rgba(255, 255, 255, 0.75);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5% 30%;
}

.confirmDialog > div {
  background: #ffffff;
  padding: 20px;
  box-shadow: 0 0 2px 4px rgba(176, 190, 197, 0.4);
}

.modalDialog {
  background: rgba(255, 255, 255, 0.75);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: 15%;
  z-index: 10;
}

.modalDialog > div {
  background: #ffffff;
  padding: 20px;
  margin: 0 85px;
  box-shadow: 0 0 2px 4px rgba(176, 190, 197, 0.4);
}

.innerAlertModal {
  background: rgba(255, 255, 255, 0.75);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 10% 20%;
  border-radius: 12px;
}

.innerAlertModal > div {
  background: #ffffff;
  border-radius: 4px;
  padding: 20px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.28);
}

.customEmptyModalBackdrop {
  opacity: 0;
}

.purpleModalHeader {
  color: #ffffff;
  background-color: #00265b;
}

.loadingIndicator {
  width: 100px;
  height: 100px;
  overflow-x: unset;
  color: #00265b;
}

.modalHeader {
  color: #ffffff;
  background-color: #00265b;
}

.blueModalHeader {
  color: #ffffff;
  background-color: #2a74d1;
}

.modalBody h2 {
  margin: 10px 0 20px 0;
}

.modalActions {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.scheduleActions {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: flex-end;
}

.btnActions {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.displayCentered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.filterBtnActions {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
}

.sketchColorPickerSwatch {
  padding: 5px;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  width: 46px;
  cursor: pointer;
  margin: auto;
}

.sketchColorPickerColor {
  cursor: pointer;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 24px;
  border-radius: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
}

.sketchColorPickerPopover {
  position: absolute;
  z-index: 2;
}

.sketchColorPickerCover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/* End Modal Styling */

/* Tables */

.table td p,
.table td h4 {
  padding: 0 !important;
  margin: 0 !important;
}

.stdTable {
  border-collapse: collapse;
}

.stdTable thead tr {
  background-color: #78909c;
  color: #ffffff;
  border: none;
  text-transform: uppercase;
}

.stdTable tbody tr {
  background-color: #f0f4f7;
  color: #455a64;
  border-top: 3px #ffffff solid;
  border-bottom: 3px #ffffff solid;
}

.stdTableWide {
  border-collapse: collapse;
  width: 100%;
}

.stdTableWide thead tr {
  background-color: #78909c;
  color: #ffffff;
  border: none;
  text-transform: uppercase;
}

.stdTableWide tbody tr {
  background-color: #f0f4f7;
  color: #455a64;
  border-top: 3px #ffffff solid;
  border-bottom: 3px #ffffff solid;
}

.stdTableWide td,
.stdTableWide th {
  padding: 10px;
}

.goalTable {
  border-collapse: collapse;
  border: 1px #cfd8dc solid;
}

.goalTable thead tr {
  background-color: #78909c;
  color: #ffffff;
  border: none;
  text-transform: uppercase;
}

.goalTable tbody tr {
  background-color: #ffffff;
  color: #455a64;
  border-top: none;
  border-bottom: none;
  border-left: 1px #cfd8dc solid;
  border-right: 1px #cfd8dc solid;
}

.goalTable tbody tr:nth-child(even) {
  background-color: #f0f4f7;
}

.GASTable {
  border-collapse: collapse;
  width: 100%;
}

.GASTable thead tr {
  background-color: #78909c;
  color: #ffffff;
  border: none;
  text-transform: uppercase;
}

.GASTable th {
  padding: 10px;
}

.GASTable td:last-of-type {
  padding-right: 10px;
  padding-top: 10px;
}

.GASTable tbody tr {
  background-color: #f0f4f7;
  color: #455a64;
  border-top: 3px #ffffff solid;
  border-bottom: 3px #ffffff solid;
}

.GASTable .selectedBase {
  background: #d8e2ec;
}

.GASTable .selectedBase td:first-of-type {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 120px !important;
}

.GASTable .baselineArrows {
  display: inline-flex;
  flex-direction: column;
  width: 40px;
  padding: 0 10px;
}

.GASTable .baselineArrows svg {
  color: #00265b;
  cursor: pointer;
}

.GASTable .selectedBase td:first-of-type div:last-of-type {
  width: 100%;
}

.BRSTable {
  border-collapse: collapse;
  width: 100%;
  border: 1px #cfd8dc solid;
}

.BRSTable thead tr th {
  background-color: #455a64;
  color: #ffffff;
  font-weight: 600;
  padding: 10px;
}

.BRSTable td {
  padding: 5px 10px;
  width: 50%;
}

.BRSTable tbody tr td[colspan="2"] {
  background-color: #78909c;
  color: #ffffff;
  font-weight: 600;
  padding: 10px;
}

.BRSTable tbody tr td:not([colspan]):not([rowspan]) {
  cursor: pointer;
}

.BRSTable tbody tr:nth-child(odd) td:not([colspan]):not([rowspan]) {
  background-color: #ffffff;
  color: #455a64;
  border: none;
}

.BRSTable tbody tr:nth-child(even) td:not([colspan]):not([rowspan]) {
  background-color: #f0f4f7;
  color: #455a64;
  border: none;
}

.BRSTable .selectedAssessment {
  background-color: rgba(103, 58, 183, 0.2) !important;
}

.BRSTempTable {
  border-collapse: collapse;
  width: 100%;
  height: 100%;
  border: 1px #cfd8dc solid;
}

.BRSTempTable thead tr th {
  background-color: #455a64;
  color: #ffffff;
  font-weight: 600;
  padding: 10px;
}

.BRSTempTable tr:hover {
  cursor: pointer;
  background-color: lightgrey;
}

.BRSTempTable tr td {
  padding: 5px 10px;
  width: 50%;
  border-bottom: 1px #cfd8dc solid;
}

.BRSTempTable .selectedAssessment {
  background-color: rgba(103, 58, 183, 0.2) !important;
}

.rosterTable {
  margin-top: 10px;
  border-collapse: collapse;
  border-bottom: 1px #f0f4f7 solid;
}

.rosterTable thead tr {
  background-color: #90a4ae;
  color: #ffffff;
  border: none;
  text-transform: uppercase;
}

.rosterTable thead th {
  font-weight: 600 !important;
  font-size: 0.8rem;
  border: none !important;
  cursor: pointer;
}

.rosterTable tbody tr:nth-child(odd) {
  background-color: #ffffff;
  color: #455a64;
  border: none;
}

.rosterTable tbody tr:nth-child(even) {
  background-color: #f0f4f7;
  color: #455a64;
  border: none;
}

.rosterTable tbody tr:hover {
  background-color: #00265b;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
}
/* .rosterTable tbody tr:hover * {
  color: #ffffff !important;
} */

.rosterTable .reset-expansion-style:hover {
  background-color: #dcedff;
  color: #484848 !important;
  cursor: default;
}


.rosterTable tbody td {
  border: none;
  font-size: 0.8rem;
}

.rosterTableEmpty tbody tr:hover {
  background-color: #ffffff;
  color: #455a64;
  cursor: default;
  transition: background-color 0.2s, color 0.2s;
}

.fixedHeightTable {
  max-height: 300px;
  overflow-y: auto;
}

/* Table for displaying student data */
.dataTable {
  margin-top: 0;
  border-collapse: collapse;
}

.dataTable thead tr {
  background-color: #90a4ae;
  color: #ffffff;
  border: none;
  text-transform: uppercase;
}

.dataTable thead th {
  font-weight: 600 !important;
  font-size: 0.8rem;
  border: none !important;
  cursor: pointer;
}

.dataTable tbody tr:nth-child(odd) {
  background-color: #ffffff;
  color: #455a64;
  border-bottom: 1px #90a4ae solid;
}

.dataTable tbody tr:nth-child(even) {
  background-color: #f0f4f7;
  color: #455a64;
  border-bottom: 1px #90a4ae solid;
}

.dataTable tbody td {
  text-align: center;
  border: none;
  font-size: 0.8rem;
  padding: 5px 10px;
}

.dataTable .dataTableName {
  text-align: left;
  min-width: 200px !important;
}

.dataTable .assessmentLabel {
  background-color: #00265b;
  text-align: center;
  border-right: 1px #ffffff solid !important;
  padding: 0px !important;
  min-width: 100px !important;
  width: 100%;

  margin: unset;
  height: inherit;
}

.assessmentLabel .dataColOptions {
  background-color: #005e89;
  padding: 2px 0;
  width: 100%;
}

.testLabel .dataColOptions {
  background-color: #2e414a;
  padding: 2px 0;
}

.dataColOptions div {
  margin: auto;
  width: 50px;
  height: 3px;
  border-radius: 2px;
  background-color: #ffffffaa;
}

.assessmentLabel .assessmentLabelDropdown,
.testLabel .testLabelDropdown {
  min-width: 80px;
  width: 100%;
}

.dataTable h4 {
  color: #ffffff;
  font-weight: 600;
  padding: 0 5px;
}

.assessmentLabelDate {
  font-size: 0.6rem;
  color: white;
}

.assessmentLabelDate svg {
  margin-right: 2px;
}

.assessmentLabelName {
  margin-bottom: 0.25rem;
}

.assessmentLabelName > div {
  color: white;
  line-height: normal;
}

.assessmentLabelName > div:not(:only-child) {
  font-size: 0.75rem;
}

.assessmentCopyText {
  font-size: 15px;
  margin-bottom: 0.25rem;
}

.removeIconBtn {
  /* position: absolute; */
  right: -30px;
  cursor: pointer;
}

.removeIconBtn > svg {
  color: red !important;
  opacity: 0.75;
}

.removeIconBtn > svg:hover {
  color: red !important;
  opacity: 1;
}

.dataTable .testLabel {
  background-color: #00c6f7;
  text-align: center;
  border-right: 1px #78909c solid !important;
  padding: 0px;
  width: 100% !important;
  height: 100%;
  cursor: pointer;
}

.dataTable .testLabel h5 {
  color: #00265b;
  /*margin-bottom: 10px;*/
  font-weight: 600;
}

.dataTable svg {
  color: #ffffff;
}

.editableDataCell {
  background-color: #683ab715;
  min-width: 80px;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
}

.editableDataCellCategory {
  padding: 5px;
}

.editableDataCellCategory div {
  width: 100%;
  font-size: 0.875rem;
}

.editableDataCellInput {
  width: 90%;
  margin-top: 5px;
  text-align: center;
  outline: none;
  box-shadow: none;
  border-radius: 4px;
  border: 1px #cfd8dc solid;
  /* padding: 10px; */
  color: #455a64;
  font-size: 0.875rem;
}

.editableDataCellOptions {
  display: flex;
  justify-content: space-between;
  margin: 0 5%;
  margin-top: -2px;
}

.editableDataCellOptions button:last-of-type svg {
  color: #8bc34a;
}

.editableDataCellOptions button:first-of-type svg {
  color: #ff0000;
}

.dataTableCell {
  width: 100%;
  padding: 5px !important;
  border-color: #ffffff00 !important;
  cursor: pointer;
}

.studentGrade {
  /*text-align: center;*/
  margin: 0 10px;
  width: 24px;
}

.studentGradeSelect {
  padding: 0 10px 0 0;
  width: 50px;
}

.dataTableCellEmpty {
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.dataTableCellEmpty:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.dataTableCellWithButton svg {
  visibility: visible;
  margin: auto;
  width: 20px;
  display: block;
  color: #1e88e5;
  transition: visibility 0.4s;
}

.dataTableCellEmpty svg {
  visibility: hidden;
  margin: auto;
  width: 20px;
  display: block;
}

.dataTableCellEmpty:hover svg {
  visibility: visible;
  color: #1e88e5;
  transition: visibility 0.4s;
}

.dataCell {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  border-radius: 5px;
  margin: auto;
  width: 90%;
  height: 100%;
  line-height: 35px;
}

.dataCell p {
  margin: 0;
  /*color: #455a64 !important;*/
  font-weight: 500;
  font-size: 0.8rem;
}

.dataCell p.category {
  -webkit-line-clamp: 3;
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-height: 1;
}

.uploadExampleTable {
  border-collapse: collapse !important;
  margin-bottom: 20px !important;
  max-width: 100%;
  overflow: scroll;
}

.uploadExampleTable thead tr {
  background-color: #d8d8d8;
  border: none;
}

.uploadExampleTable td,
.uploadExampleTable th {
  border: 1px #4a4a4a solid !important;
  color: #484848;
  padding: 5px !important;
  white-space: nowrap !important;
}

.selectDataColumnTable {
  border-collapse: collapse;
  text-align: center;
  color: #455a64 !important;
  font-size: 0.8rem;
  min-width: 700px;
}

.selectDataColumnTableContainer {
  overflow-x: scroll;
}

.selectDataColumnTable th {
  border: none !important;
}

.selectDataColumnTable td:nth-of-type(2) {
  max-width: 120px !important;
}

.selectDataColumnTable tbody tr {
  border: 1px #b0bec5 solid !important;
}

.selectDataColumnTable td {
  border: none !important;
  padding: 5px !important;
}

.selectDataColumnTable td input {
  text-align: center;
}

.colorPickerTable {
  border: none;
  border-collapse: collapse;
  text-align: center;
  color: #455a64 !important;
  font-size: 0.8rem;
  margin: auto;
}

.colorPickerTable th {
  padding: 0 5px 10px 5px !important;
  border: none !important;
}

.colorPickerTable td {
  padding: 0 5px 10px 5px !important;
  border: none !important;
}

.colorPickerTable input {
  text-align: center;
  display: inline-block;
}

.colorCriteriaDesc {
  max-width: 180px !important;
}

.stdTableRadio {
  width: 100%;
  margin: 20px 0;
}

.stdTableRadio .tableHeader {
  background-color: #78909c;
  color: #ffffff;
  padding: 20px 0;
  font-weight: 500;
}

.tableContentColumn {
  padding: 0 10px;
  display: inline-block;
  width: 200px;
}

.tableRadioColumn {
  padding: 0 10px;
  display: inline-block;
  width: 60px;
  text-align: center;
}

.tableItem {
  background-color: #f0f4f7;
  color: #455a64;
  margin: 2px 0;
  padding: 10px 0;
}

.rangeDiv {
  color: #2a74d1;
  background-color: #f0f4f7;
  border-radius: 2px;
  padding: 5px 8px;
}

/* Onboarding Summary */

.onboardingSummaryContainer {
  background-color: #2a74d1;
  padding: 30px;
  border-radius: 12px;
  border: 20px #ffffff solid;
  margin: 20px;
}

.onboardingSummaryInnerContainer {
  display: flex;
  align-items: center !important;
}

.onboardingSummaryInnerLeft {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  max-width: 480px !important;
}

.onboardingSummaryInnerLeft * {
  max-width: 480px !important;
  text-align: center;
}

.onboardingSummaryInnerLeft h1 {
  margin: 20px 0 !important;
  font-weight: 300;
}

.onboardingSummaryInnerLeft h2 {
  margin: 0;
  font-weight: 300;
}

.onboardingSummaryInnerLeft p {
  margin: 20px 0 !important;
  font-weight: 300 !important;
}

.onboardingSummaryInnerRight {
  max-width: 480px !important;
  margin: auto;
}

.onboardingSummaryInnerRight h2 {
  text-align: center;
  margin: 20px 0;
  font-weight: 300;
  max-width: 480px;
}

.onboardingSummaryStepContainer {
  background-color: #f0f4f7;
  padding: 20px;
  border-radius: 12px;
  max-width: 480px;
  margin: auto;
}

.onboardingSummaryStepContainer a:hover {
  text-decoration: none !important;
}

.onboardingSummaryStepRow {
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0 2px 6px rgba(32, 32, 32, 0.3);
  border-radius: 12px;
  margin: 0 0 20px 0 !important;
  align-items: center;
}

.onboardingSummaryStepRowLast {
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0 2px 6px rgba(32, 32, 32, 0.3);
  border-radius: 12px;
  margin: 0 !important;
  align-items: center;
}

.onboardingSummaryStepRow > div,
.onboardingSummaryStepRowLast > div {
  border-left: 1px #cfd8dc solid;
}

.onboardingSummaryStepRow p,
.onboardingSummaryStepRowLast p {
  font-size: 0.8rem;
  margin: 0;
}

.onboardingSummaryStepRow:hover,
.onboardingSummaryStepRowLast:hover {
  text-decoration: none !important;
  background: #fcfcfc; /* Old browsers */
  background: -moz-linear-gradient(
    180deg,
    #ffffff 40%,
    #fcfcfc 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    180deg,
    #ffffff 40%,
    #fcfcfc 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    180deg,
    #ffffff 40%,
    #fcfcfc 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  box-shadow: 0px 2px 6px rgba(32, 32, 32, 0.2);
  transition: box-shadow 0.4s;
}

.onboardingSummaryStepRow:active,
.onboardingSummaryStepRowLast:active {
  box-shadow: none;
}

.onboardingSummaryStepIconBlue {
  background: #1e88e5; /* Old browsers */
  background: -moz-linear-gradient(
    45deg,
    #1e88e5 0%,
    #283593 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    45deg,
    #1e88e5 0%,
    #283593 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    45deg,
    #1e88e5 0%,
    #283593 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  color: #ffffff;
  text-align: center;
  height: 64px;
  width: 64px;
  border-radius: 32px;
  font-size: 1.5rem;
  line-height: 64px;
}

.onboardingSummaryStepIconGreen {
  background-color: #8bc34a;
  color: #ffffff;
  text-align: center;
  height: 64px;
  width: 64px;
  border-radius: 32px;
  font-size: 1.5rem;
  line-height: 64px;
}

.onboardingSummaryStepIconGreen svg {
  color: #ffffff;
}

.onboardingSummaryStepIconCol {
  padding: 0 20px 0 0 !important;
  border: none !important;
}

/* Onboarding Step 1 */

.stepInfoTooltip {
  position: absolute;
  font-size: 0.75rem;
  max-width: 500px;
  color: #455a64;
  background-color: #ffffff;
  box-shadow: 0px 0px 11px rgba(32, 32, 32, 0.5);
  border-radius: 8px;
  padding: 10px;
  z-index: 999;
}

.onboardingContainer {
  padding: 8px 8px 8px 8px;
  background-color: #ffffff;
  border: 1px white solid;
  border-radius: 4px;
}

.onboardingContainer h2 {
  margin-top: 0;
  margin-bottom: 8px;
}

.dataTableContainer {
  /* padding: 5px 20px 20px; */
  background-color: #ffffff;
  border-radius: 4px;
  /* margin-bottom: 10px; */
}

.onboardingFirstStepSecondTabListItem {
  font-weight: 300;
  margin: 20px 0;
}

.stepPanel {
  background-color: #ffffff;
  padding: 10px !important;
  margin-bottom: 10px !important;
  border-radius: 12px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.stepper {
  width: 120px;
  background-color: red;
  align-items: center;
  display: flex;
}

.stepIndicator {
  width: 120px;
  height: 32px;
  font-size: 1rem;
  text-align: center;
  display: flex;
  align-items: center;
  font-weight: 600;
}

.stepIndicatorItemActive {
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background: linear-gradient(190deg, #283593 0%, #1e88e5 100%);
  color: #ffffff;
  display: inline-block;
  line-height: 32px;
}

.stepIndicatorItemComplete {
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background-color: #8bc34a;
  color: #ffffff;
  display: inline-block;
  line-height: 32px;
}

.stepIndicatorItemComplete svg {
  color: #ffffff;
}

.stepIndicatorItemPending {
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background-color: #b0bec5;
  color: #ffffff;
  display: inline-block;
  line-height: 32px;
}

.stepIndicatorGreenLine {
  width: 12px;
  height: 4px;
  background-color: #8bc34a;
  display: inline-block;
}

.stepIndicatorGreyLine {
  width: 12px;
  height: 4px;
  background-color: #b0bec5;
  display: inline-block;
}

.stepperInfo {
  padding: 0 20px;
}

.stepperInfo p {
  font-size: 0.75rem;
}

.stepPanel h5 {
  color: #2a74d1;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0;
}

.stepPanel .container,
.stepPanel [class^="col"] {
  padding: 0 !important;
  position: relative;
}

.stepper > div > div > div > div > a,
.stepper > div > div > div > div > span {
  line-height: 32px !important;
}

.stepper > div > div > div > a {
  margin-top: 0 !important;
}

.stepper > div > div > div > div {
  bottom: 22px !important;
}

.firstTabContainer {
  padding: 30px 0;
}

.firstTabContainer img {
  max-height: 400px;
}

.firstTabCircleItem {
  text-align: center;
  margin: auto;
  height: 250px;
  width: 250px;
  padding: 15px;
  border-radius: 100%;
  box-shadow: 0px 0px 13px rgba(70, 90, 100, 0.55);
  color: #455a64;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
  font-weight: 400 !important;
}

.firstTabCircleItem h2 {
  font-weight: 400 !important;
}

.firstTabCircleItem svg {
  margin: 0 auto !important;
}

.firstTabCircleItem p {
  font-size: 0.75rem;
}

/* Onboarding Step 2 */

.noParentsViewContainer {
  background: #f0f4f7;
  border-radius: 8px;
  padding: 80px 120px;
  text-align: center;
}

.onboardingSecondStepEmptyContainer {
  background: #f0f4f7;
  border-radius: 8px;
  padding: 80px 120px;
  text-align: center;
  margin-top: 25px;
}

.onboardingSecondStepEmptyContainer img {
  margin-top: -10px;
}

.collectEvidenceEmptyContainer {
  background-color: #f0f4f7;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0px 0px 4px rgba(32, 32, 32, 0.3);
  padding: 50px;
  color: #455a64;
}

.collectEvidenceEmptyContainerBtns {
  display: flex;
  margin: auto;
  justify-content: center;
}

.mainHeaderMobileNav {
  display: none;
  padding: 2px 5px;
  border: 2px #ffffff solid;
  border-radius: 4px;
}

.mainHeaderMobileNav svg {
  color: #ffffff;
}

.mobileHeaderDropdown {
  background-color: #ffffff;
  box-shadow: 0px 1px 1px rgba(240, 240, 240, 0.9);
  border-radius: 0 0 4px 4px;
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  color: #455a64;
  z-index: 999;
}

.mobileHeaderDropdown a {
  text-transform: uppercase;
  color: #455a64;
  font-weight: 700;
}

.mobileHeaderDropdown a:hover,
.mobileHeaderDropdown a:hover a,
.mobileHeaderDropdown a:hover svg {
  background-color: #f0f4f7;
  color: #1e88e5;
  text-decoration: none;
  transition: background-color 0.4s, color 0.4s;
}

.mobileHeaderDropdown hr {
  margin: 0 !important;
}

.mobileUserOptions > div {
  padding: 0.5rem 1rem;
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.mobileUserOptions > div:hover {
  background-color: #f0f4f7;
  color: #1e88e5;
  transition: background-color 0.4s, color 0.4s;
}

.mobileUserOptions img {
  margin-right: 10px;
}

.mobileNavIcon {
  display: inline-block;
  width: 30px;
  text-align: center;
  margin-right: 10px;
}

.mobileUserOptions > div:hover svg {
  color: #1e88e5;
  transition: color 0.4s;
}

.menuOverlay {
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(70, 90, 100, 0.55);
  z-index: 100;
  display: none;
}

.addStudentWidget {
  background: #f7f4fc;
  box-shadow: 0 0 16px rgba(32, 32, 32, 0.5);
  padding: 10px 15px;
  position: absolute;
  right: 0;
  top: -5px;
  border-radius: 4px;
}

.thumbnailIcon {
  display: inline-block;
  height: 28px;
  width: 28px;
  border-radius: 14px;
  border: 2px #00265b solid;
}

.horizontalScrollContainer {
  overflow-x: hidden;
}

.horizontalScrollContainer div {
  overflow-x: scroll;
  scrollbar-width: none;
}

.horizontalScrollContainer div::-webkit-scrollbar {
  width: 0 !important;
}

.actionInfoSmallIcon {
  display: none;
}

.filterSearchHeight {
  height: 38px !important;
}

.filterSearchDesktop {
  display: flex;
}

.filterSearchMobile {
  margin-top: 10px;
  width: 100%;
  display: none !important;
}

.rosterSettingsHeader {
  background-color: #00265b;
  color: #ffffff !important;
  font-weight: 600;
}

.viewBenchmarksModal {
  padding: 12px !important;
}

.viewBenchmarksModalContainer > div > div {
  border-radius: 2px !important;
  background: #ffffff;
  box-shadow: 0 0 2px 4px rgba(176, 190, 197, 0.4);
}

.viewBenchmarksTable {
  margin-top: 10px;
}

.viewBenchmarksTable td,
.viewBenchmarksTable th {
  padding: 5px 10px !important;
  text-align: center;
  border: 1px #acbbc3 solid;
  /*color: #455a64;*/
}

.studentGroupDropContainer {
  width: 100%;
  background-color: #f0f4f7;
  padding: 10px;
  border-radius: 8px;
}

.studentGroupDropContainer p {
  border: 1px #cfd8dc dashed;
  padding: 5px;
  border-radius: 4px;
  margin: 0;
}

.groupInfo {
  width: 100%;
  border: 1px #cfd8dc solid;
  border-radius: 8px;
  margin-top: 10px;
}

.groupCount {
  text-align: center;
  align-items: center;
  /*width: 140px;*/
  margin: auto;
  justify-content: center;
}

.groupCountSvg circle {
  fill: #a489d4;
}

.whiteGroupCountIcon {
  cursor: pointer;
}

.whiteGroupCountIcon svg > text {
  fill: #00265b;
  font-weight: bold;
  font-size: 15px;
}

.whiteGroupCountIcon svg > circle {
  fill: #a489d4;
}

.notificationBubble circle {
  fill: #ff9800;
}

.notificationBubble text {
  fill: #ffffff;
  font-size: 1.25rem;
  font-weight: bold;
}

.groupInfoContent {
  width: 90%;
}

.groupInfoContentEstablished {
  vertical-align: top;
  display: inline-block;
  width: 90%;
}

.groupInfoStudentContainer {
  display: inline-block;
  width: 90%;
}

.students-section {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
  width: calc(100% + 10px) !important;
}

.students-section > .studentGroupCell {
  flex-basis: calc(50% - 10px);
  overflow: hidden;
}

.students-section > .studentGroupCell > div {
  display: flex;
  justify-content: space-between;
}

.students-section > .studentGroupCell .studentGroupCellName {
  overflow: hidden;
  flex: 1;
}

.students-section > .studentGroupCell .studentGroupCellName > span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.groupStudentLabel {
  display: inline-block;
  width: 10%;
}

.tagsContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.tagsContainer > .tagCell {
  background-color: #ffffff;
  padding: 0 10px;
  cursor: unset;
}

.studentGroupCell,
.tagCell,
.fileCell {
  display: inline-block;
  margin: 5px;
  border: 1px #b0bec5 solid;
  border-radius: 4px;
  cursor: pointer;
}

.studentGroupCellName,
.fileCellName {
  display: inline-block;
  padding: 0 5px;
}

.studentGroupCellRemove,
.fileCellRemove {
  background-color: #b0bec5;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
  cursor: pointer;
}

.studentGroupCellRemove:hover,
.fileCellRemove:hover {
  background-color: rgb(223, 227, 230);
  transition: background-color 0.2s;
}

.fileCell + .active {
  background-color: #d2e0e7;
}

.goalTag {
  padding: 0 5px;
  background: #eff4f7;
  border: 1px #b0bec5 solid;
  border-radius: 4px;
  cursor: pointer;
}

.groupDetails {
  background-color: #f0f4f7;
  border-top: 1px #b0bec5;
  width: 100%;
  padding: 5px 0;

  display: flex;
  flex-wrap: wrap;
}

.groupDetails > div {
  flex: 1 25%;
  min-width: 200px;
  flex-basis: 25%;
}

.groupDetails input::placeholder {
  color: hsl(0, 0%, 50%);
  font-weight: 400;
  font-size: 16px;
}

.groupCountTable {
  background-color: #90a4ae;
  height: 40px;
  width: 40px;
  line-height: 40px;
  font-size: 0.8rem;
  color: #ffffff;
  border-radius: 20px;
  text-align: center;
  margin: auto;
  margin-top: 20px;
}

.newGroupModalNames {
  background: #cfd8dc;
  border-radius: 4px;
  padding: 10px;
  text-align: center;
  margin-bottom: 30px;
}

.newGroupModalNames > div > div {
  background: #ffffff;
  border: 1px solid #b0bec5;
  border-radius: 4px;
  padding: 3px;
  margin-bottom: 5px;
}

.newGroupModalNames > div > div:last-of-type {
  margin-bottom: 0;
}

/* Intervention Modals */

.interventionModalSidebar {
  background: #9575cd25;
  padding: 30px 20px;
  color: #455a64;
  height: 100%;
}

.interventionModalContent {
  padding: 20px 20px;
  margin: auto;
}

.interventionModalInnerContent {
  margin: 10px 75px;
}

.interventionModalContentNoPad {
  padding: 20px 0px;
  margin: auto;
  text-align: center;
}

.interventionModalContentNoPad .purpleText {
  padding: 0 20px;
  text-align: center;
}

.interventionModalContentNoPad .modalActions {
  padding: 0 20px;
}

.setGoalChooseConcernContainer {
  text-align: center;
  margin-top: 20px;
}

.setGoalChooseConcernContainer .blueBtnXL {
  display: inline-block;
  margin: 0 10px;
}

.interventionStepsContainer {
  border: 1px #c3c3c3 solid;
  margin-top: 30px;
  text-align: center;
}

.interventionStepsContainerHeader {
  background: #f6f6f6;
  border-bottom: 1px #c3c3c3 solid;
  padding: 5px 10px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.interventionStepsContainerHeader div {
  display: inline-block;
}

.interventionStepsContainerHeader svg {
  color: #00265b;
  cursor: pointer;
}

.interventionStepsContainerHeader p {
  margin: 0;
}

.interventionStepsContainerBody {
  padding: 0;
}

.interventionStepItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px #979797 solid;
  padding: 0.5rem 1rem;
}

.interventionStepItem > div:first-of-type {
  display: flex;
  justify-content: center;
  align-items: center;
}

.interventionStepItem > div:first-of-type h3 {
  margin: 0;
  border: 2px #455a64 solid;
  border-radius: 20px;
  height: 40px;
  width: 40px;
  line-height: 36px;
  font-weight: 600;
}

.interventionStepItem > div:nth-of-type(2) {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 0 1rem;
  flex-grow: 1;
}

.interventionStepItem > div:nth-of-type(2) h3,
.interventionStepItem > div:nth-of-type(2) p {
  margin: 0;
}

.interventionStepItem > div:last-of-type {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.interventionStepItem > div:last-of-type > span {
  padding: 0.25rem;
}

.interventionStepItem svg {
  color: #00265b;
  cursor: pointer;
}

.interventionStepFileContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.interventionStepFileContainer > .interventionStepFile + .interventionStepFile {
  margin-left: 10px;
}

.interventionStepFileContainer > .fileCell {
  display: flex;
}

.interventionStepFile {
  cursor: pointer;
  color: #00265b;
}

.interventionStepFile:hover {
  text-decoration: none;
}

.interventionStepFile:hover > svg {
  color: #673ab7;
}

.interventionStepsContainerBodyEmpty {
  padding: 20px 10px;
}

.interventionStepsContainerBodyEmpty .whiteBtnXL {
  margin: auto;
}

.interventionStepDetailContainer {
  margin: 10px 20px;
  background: #f7f4fc;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0px 0px 6px rgba(32, 32, 32, 0.2);
}

.interventionStepDetailContainer h3,
.interventionStepDetailContainer p {
  margin: 0;
}

.interventionStepDetailResourceContainerEmpty {
  padding: 20px;
  text-align: center;
  background: #f8f8f8;
  border: 1px solid #cfd8dc;
  border-radius: 12px;
  margin-bottom: 20px;
}

.interventionStepDetailResourceContainerEmpty .blueBtnSm {
  display: inline-block;
  margin: 0 10px;
}

.innerSmallModal {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(255, 255, 255, 0.8);
}

.interventionStepDetailContainerResource {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  background: #ffffff;
  box-shadow: 0 0 2px 4px rgba(176, 190, 197, 0.4);
  border-radius: 2px;
  padding: 20px;
}

.interventionDetailsPurpleLabel {
  background: rgba(103, 58, 183, 0.2);
  padding: 2px 10px;
}

.interventionModalContentNoPad h3 {
  padding: 40px 0 !important;
}

.interventionDetailsPurpleLabel p {
  margin: 0;
  color: #00265b;
  font-weight: 600;
  text-align: center;
}

.whitePillBtn {
  border: 1px solid #455a64;
  border-radius: 20px;
  text-align: center;
  color: #455a64;
  background-color: #ffffff;
  margin-bottom: 40px;
  padding: 5px 0px;
  min-width: 30%;
  cursor: pointer;
  font-size: 1rem;
}

.interventionGroupTooltip {
  position: absolute;
  top: 0;
  left: 0;
  color: #455a64;
  background-color: #00265b;
  box-shadow: 0px 0px 11px rgba(32, 32, 32, 0.5);
  border-radius: 12px;
  z-index: 999;
  padding: 1rem 2.2rem;
  min-width: 30%;
}

.purplePillBtn {
  background-color: inherit;
  border: 2px solid #9575cd;
  border-radius: 20px;
  text-align: center;
  color: #9575cd;
  margin: 20px 5px;
  display: inline-block;
  min-width: 120px;
  cursor: pointer;
  font-size: 1rem;
}

.purplePillBtn:active,
.purplePillBtn:focus,
.purplePillBtnSelected:active,
.purplePillBtnSelected:focus {
  outline: none;
}

.purplePillBtnSelected {
  background: #00265b;
  border: 2px solid #00265b;
  border-radius: 20px;
  text-align: center;
  color: #ffffff;
  margin: 20px 5px;
  display: inline-block;
  min-width: 120px;
  cursor: pointer;
}

.purplePillBtnLg {
  border: 2px solid #9575cd;
  border-radius: 20px;
  text-align: center;
  color: #9575cd;
  margin: 20px 10px;
  display: inline-block;
  min-width: 200px;
  cursor: pointer;
  font-size: 1.2rem;
  padding: 5px;
}

.purplePillBtnLg.active {
  border: 2px solid #00265b;
  background: #00265b;
  color: #ffffff;
}

.blueIconBtn {
  color: #00265b;
}

.blueIconBtn:hover {
  cursor: pointer;
  color: #2547a5;
}

.interventionStepsBtnWithPlaceholder {
  background: #00265b linear-gradient(to right, #00265b 10%, #f7f4fc 10%);
  border-radius: 10px !important;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  min-height: 65px;
  cursor: pointer;
  font-size: 1rem;
}

.interventionStepsBtnWithPlaceholder div:nth-child(1) {
  text-align: center;
  color: #ffffff;
  font-weight: 700;
  width: 10%;
}

.interventionStepsBtnWithPlaceholder div:nth-child(2) {
  width: 85%;
  margin: 10px 0px 10px 20px;
  color: #455963;
}

.interventionStepsBtnWithPlaceholder svg:nth-child(3) {
  width: 5%;
}

.interventionStepsBtnWithPlaceholder:hover,
.interventionStepsBtnWithPlaceholder:focus {
  background: #00265b linear-gradient(to right, #4f18b4 10%, #f7e1fc 10%);
  transition: background-color 0.4s;
  outline: none;
}

.bgInfoBtn {
  border: 1px #9f82d1 solid;
  background-color: inherit;
  border-radius: 4px !important;
  padding: 5px 15px !important;
  color: #ffffff;
  font-weight: 600 !important;
  font-size: 0.8rem !important;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
}

.bgInfoBtn:hover,
.bgInfoBtn:focus {
  background: #4f18b4;
  transition: background-color 0.4s;
  outline: none;
}

.widePill {
  display: flex;
  flex-direction: row;
  padding: 15px;
  border-radius: 8px;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.widePill > svg {
  margin-right: 15px;
}

.widePill div {
  display: inline-flex;
  flex-direction: column;
  text-align: left;
}

.widePill.active {
  background-color: rgba(149, 117, 205, 0.1);
}

.studentTargetWidget {
  width: 100%;
  display: flex;
  align-items: stretch;
}

.studentTargetWidgetNames {
  display: inline-block;
  background: #f0f4f7;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.28);
  border-radius: 8px;
  margin-right: 20px;
  min-width: 30%;
  height: 100%;
  align-self: center;
}

.studentTargetWidgetNames h4 {
  padding: 5px 10px;
  margin: 0;
  cursor: pointer;
}

.studentTargetWidgetNames h4:first-of-type {
  border-radius: 8px 8px 0 0;
}

.studentTargetWidgetNames h4:last-of-type {
  border-radius: 0 0 8px 8px;
}

.studentTargetWidgetNames h4:only-of-type {
  border-radius: 8px;
}

.studentTargetWidgetNames .selectedStudentWidget {
  box-shadow: inherit;
  background-color: #455a64;
  font-weight: 600;
  color: #ffffff;
}

.studentTargetWidgetInfo {
  background: #f0f4f7;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.28);
  border-radius: 8px;
  padding: 10px;
  display: inline-flex;
  width: inherit;
  align-items: center;
}

.studentTargetWidgetInfo div {
  margin-left: 10px;
}

.studentTargetWidgetDescription {
  background: #f0f4f7;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.28);
  border-radius: 8px;
  padding: 10px;
  width: inherit;
}

.studentTargetWidgetTextarea {
  width: 100%;
  border-radius: 4px;
  border: 1px #cfd8dc solid;
  padding: 10px;
  color: #455a64;
  font-size: 0.875rem;
  outline: none;
  box-shadow: none;
}

.libraryLabel {
  background: #738d9a;
  border-radius: 8px;
  color: #ffffff;
  padding: 10px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.libraryLabel svg {
  color: #ffffff;
  cursor: pointer;
}

.interventionList {
  overflow-y: auto;
  max-height: 400px !important;
}

.interventionList.nav {
  flex-wrap: nowrap;
}

.interventionList::-webkit-scrollbar {
  width: 10px;
}

.interventionList::-webkit-scrollbar-track {
  background: #f0f4f7;
}

.interventionList::-webkit-scrollbar-thumb {
  background: #00265b;
}

.interventionItem {
  background: #f0f4f7;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  cursor: pointer;
}

.interventionItemBody {
  width: 100%;
  display: flex;
  align-items: start;
}

.interventionItemIcon {
  color: #ffffff;
  background: #455a64;
  border-radius: 12px;
  height: 24px;
  width: 24px;
  line-height: 24px;
  display: inline-block;
  margin-right: 5px;
  text-align: center;
}

.interventionItemDescription h3 {
  display: inline-block;
  font-weight: 600;
  margin-bottom: 0;
}

.interventionItemDescription {
  margin: 1rem;
  flex: 2 1;
}

.interventionItemDetails {
  margin: 1rem;
  align-self: center;
  /*flex-basis: 25%;*/
}

.interventionItemDescription > div {
  margin-bottom: 0.75rem;
}

.interventionStepsTooltipBorder {
  position: relative;
  border: 1px solid #00265b;
  padding: 20px;
  box-sizing: border-box;
}

.interventionStepsTooltipBorder .text {
  color: #00265b;
}

.interventionStepsTooltipBorder:after {
  position: absolute;
  width: 10px;
  height: 10px;
  border-top: 0;
  border-bottom: 1px solid #00265b;
  border-left: 0;
  border-right: 1px solid #00265b;
  top: 100%;
  left: 15%;
  content: "";
  margin-top: -4px;
  transform: rotate(45deg);
  background: #ffffff;
}

.interventionPdfButton {
  display: inline-block;
  background-color: #00265b;
  color: #ffffff;
  font-weight: 600;
  padding: 5px;
  border-radius: 0 0 12px 0;
  width: 80px;
  text-align: center;
  cursor: pointer;
}

.interventionPdfButton svg {
  color: #ffffff;
  margin-right: 3px;
}

.pdfDropdown {
}

.pdfDropdown > .dropdown-menu {
  top: 0 !important;
  left: 0 !important;
}

.goalDeadlineContainer {
  background: #f0f4f7;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  margin: 20px 0;
}

.datePickerBtn {
  background: #ffffff;
  border: 1px solid #b0bec5;
  border-radius: 8px;
  padding: 5px 10px;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  cursor: pointer;
}

.datePickerBtn.right {
  display: flex;
  align-items: center;
  border-radius: 8px 0 0 8px;
  white-space: nowrap;
}

.datePickerBtn.left {
  display: flex;
  align-items: center;
  border-radius: 0 8px 8px 0;
  white-space: nowrap;
}

.datePickerBtn svg {
  margin-right: 3px;
}

.datePickerBoxBtn {
  background: #ffffff;
  border: 1px solid #b0bec5;
  min-width: 130px;
  padding: 5px 10px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  cursor: pointer;
}

@keyframes zoomOutAnimation {
  from {
    opacity: 1;
    margin-left: var(--start-left-pos);
    margin-top: var(--start-top-pos);
  }

  70% {
    opacity: 0.65;
    transform: scale(0.5, 0.5);
    margin-left: var(--left-pos);
    margin-top: var(--top-pos);
  }

  to {
    opacity: 0;
    transform: scale(0, 0);
    margin-left: var(--left-pos);
    margin-top: var(--top-pos);
  }
}

.zoomOut {
  animation: zoomOutAnimation 1s linear forwards;
}

.newGroupModal {
  max-width: 420px !important;
}

@media only screen and (max-width: 600px) {
  .filterSearchMobile {
    display: flex !important;
    height: 32px;
  }

  .filterSearchMobile input {
    height: 32px;
  }

  .filterSearchDesktop {
    display: none !important;
  }

  .menuSeparator {
    display: none;
  }

  .BlueBtnSm,
  .BlueBtnMd,
  .BlueBtnLg,
  .blueBtnXL {
    margin-bottom: 10px;
  }

  .whiteBtnXL {
    min-height: auto;
  }

  .whiteBtnXL svg {
    margin-bottom: 10px;
    font-size: 3rem;
  }

  .whiteBtnXLTitle {
    font-size: 1.1rem;
  }

  .whiteBtnXLText {
    font-size: 0.9rem;
  }

  .uploadExampleTable {
    font-size: 0.8rem;
  }

  .rosterTable thead th {
    font-size: 0.7rem;
    padding: 0.2rem 0.4rem;
    display: table-cell;
    vertical-align: middle !important;
  }

  .rosterTable tbody td {
    font-size: 0.7rem;
    padding: 0.2rem 0.4rem;
  }

  .onboardingContainer {
    /* padding: 10px 5px; */
    width: auto;
  }

  .mainHeader {
    margin-bottom: 10px;
  }

  .contentContainer {
    padding: 0 10px !important;
  }

  .btnActions div {
    font-size: 0.7rem !important;
  }

  .onboardingSecondStepEmptyContainer {
    padding: 20px !important;
  }

  .mainHeaderUserOptions {
    display: none;
  }

  .mainHeaderMobileNav {
    display: inline-block;
    cursor: pointer;
  }

  .mobileHeaderDropdown {
    /* display: block; */
    transition: display 0.4s;
  }

  .onboardingSummaryContainer {
    padding: 20px 10px !important;
    margin: 10px !important;
    border: 10px #ffffff solid;
  }

  .onboardingContainer h1 {
    font-size: 1.5rem !important;
  }

  .onboardingContainer h3 {
    font-size: 1rem !important;
  }

  .firstTabContainer {
    margin: 0 !important;
    padding: 10px 0 !important;
  }

  .stepPanel {
    padding: 10px !important;
    flex-direction: column;
  }

  .stepPanel h4 {
    font-size: 0.8rem !important;
  }

  .stepperInfo {
    padding: 0 10px;
    margin-bottom: 10px;
  }

  .collectEvidenceEmptyContainer {
    margin: 0 10px;
    padding: 20px;
  }

  .whiteBtnMd p {
    font-size: 0.8rem;
  }

  .whiteBtnMd {
    font-size: 0.8rem;
    padding: 5px 10px;
  }
}

@media only screen and (max-width: 800px) {
  .actionInfoSmallIcon {
    display: inline-block;
  }

  .actionInfoBigIcon {
    display: none;
  }

  .actionInfoContainer {
    padding: 10px;
    display: flex;
    flex-direction: column;
  }

  .actionInfoContainer .px-4 {
    padding: 0 !important;
  }

  .actionInfoContainer h2 {
    font-size: 0.9rem !important;
    margin: 0 !important;
  }

  .actionInfoContainer h4 {
    font-size: 0.7rem !important;
    margin: 0 !important;
  }

  .actionInfoContainer .blueBtnMd {
    margin-top: 10px !important;
    font-size: 0.8rem !important;
    padding: 5px 15px !important;
    border-radius: 4px !important;
  }

  .actionInfoContainer .whiteActionBtn {
    margin-top: 10px !important;
    padding: 5px 20px;
    font-size: 0.9rem !important;
  }

  .actionInfoContainer > div > div:first-of-type {
    padding-right: 10px;
  }

  .actionInfoContainer > div > div:nth-of-type(2) {
    padding: 0;
  }

  .collectEvidenceEmptyContainerBtns {
    flex-direction: column;
  }

  .collectEvidenceEmptyContainerBtns div {
    margin: auto;
    margin-bottom: 10px;
  }

  .whiteBtnXL {
    padding: 20px 10px;
  }

  .mainHeader {
    padding: 0 20px !important;
    margin-bottom: 10px;
  }

  .onboardingSummaryStepContainer {
    padding: 10px;
  }

  .onboardingSummaryStepRow,
  .onboardingSummaryStepRowLast {
    padding: 10px;
  }

  .onboardingSummaryStepIconCol {
    padding: 0 10px 0 0 !important;
  }

  .onboardingSummaryStepRow {
    margin: 0 0 10px 0 !important;
  }

  .loginContainer {
    padding: 30px 20px !important;
    margin-top: 20px;
  }

  .welcomeInputContainer {
    padding: 15px;
  }

  .groupDetails input,
  .groupDetails .col-md-3 > div {
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 1000px) {
  .onboardingSecondStepEmptyContainer {
    padding: 40px;
  }

  .onboardingSecondStepEmptyContainer img {
    margin-top: 0px;
    display: block;
    margin: auto;
  }

  .onboardingSecondStepEmptyContainer .blueBtnXL {
    margin-bottom: 10px;
  }

  .onboardingSummaryContainer {
    padding: 60px 20px;
    margin: 20px;
  }

  .whiteBtnXL {
    padding: 10px;
  }

  .whiteBtnMdTitle {
    font-size: 1rem;
  }
}

/*Behavior Contacts Modal*/
.behavConLeftActions {
  align-items: end;
  height: min-content;
  display: flex;
}

.behavConLeftActions > * {
  margin-right: 10px;
}

/*About Target Behaviors Modal*/
.domainsTable {
  width: 48%;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.domainsTableHeader {
  background-color: #78909c;
  color: #ffffff;
  border: none;
  text-transform: uppercase;
  font-weight: 600;
  padding: 15px 40px;
}

.domainsTableBody {
  overflow-y: auto;
  max-height: 250px;
}

.domainsTableBody > div {
  padding: 10px;
  color: #455a64;
  border: none;
  cursor: pointer;
}

.domainsTableBody > div > svg {
  width: 0.625em !important;
  margin: 0 10px;
}

.domainsTableBody > div:nth-child(odd) {
  background-color: #ffffff;
}

.domainsTableBody > div:nth-child(even) {
  background-color: #f0f4f7;
}

.domainActivePillButton {
  text-align: center;
  padding: 10px 5px;
  background-color: #00265b;
  border-radius: 12px;
  font-size: 0.9rem;
  color: #ffffff;
}

.domainPillButton {
  text-align: center;
  padding: 10px 5px;
  background-color: rgb(255, 171, 0);
  border-radius: 12px;
  font-size: 0.9rem;
}

.behaviorsListTable {
  border: 1px solid rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

.behaviorsListTableBody {
  padding: 0 !important;
}

.behaviorsListTableBody > div {
  padding: 5px 20px;
  color: #455a64;
  border: none;
  cursor: pointer;
}

.behaviorsList {
  padding: 0 !important;
  color: #455a64;
  border: none;
  cursor: pointer;
}

.behaviorsList > div {
  padding: 0 5px;
}

.behaviorsList > div:nth-child(odd) {
  background-color: #f6f7f8;
}

.behaviorsList > div:nth-child(even) {
  background-color: #f6f7f8;
}

.behaviorsList > .active {
  color: #00265b;
  border: 1px solid #00265b;
  background-color: rgb(255, 171, 0) !important;
  font-weight: bold;
  margin-top: -1px;
}

.behaviorsList > .highlighted {
  background-color: #bff9dd !important;
}

.domainHeader {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.domainHeader > svg {
  margin-right: 10px;
  width: 0.625em !important;
}

.behaviorsListTableHeader {
  background-color: #78909c;
  color: #ffffff;
  border: none;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.behaviorsListTableHeader > svg {
  color: #ffffff;
  margin-right: 10px;
  width: 0.625em !important;
}

.findTargetTable {
  width: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  max-height: 500px;
}

.findTargetTableHeader {
  background-color: #78909c;
  color: #ffffff;
  border: none;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 50px;
}

.findTargetTableHeader > svg {
  color: #ffffff;
  margin-right: 10px;
  width: 0.625em !important;
}

.findTargetTableBody > div {
  padding: 10px 20px;
  color: #455a64;
  border: none;
  cursor: pointer;
}

.findTargetTableBody > div:nth-child(odd) {
  background-color: #ffffff;
}

.findTargetTableBody > div:nth-child(even) {
  background-color: #f0f4f7;
}

.findTargetTableBodyActiveItem {
  background-color: #00265b !important;
  color: #ffffff !important;
  font-weight: 400;
}

.selectBehaviorInfoContainer {
  padding: 20px 40px;
  background-color: #f0f4f7;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 0 4px rgba(32, 32, 32, 0.3);
}

.findTargetDomainsTable {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.findTargetDomainsTableHeader {
  background-color: #00265b;
  color: #ffffff;
  border: none;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 50px;
}

.findTargetDomainsTableBody > div {
  padding: 10px;
  color: #455a64;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.findTargetDomainsTableBody > div:nth-child(odd) {
  background-color: #ffffff;
}

.findTargetDomainsTableBody > div:nth-child(even) {
  background-color: #f0f4f7;
}

.findTargetDomainsTableBody > div > svg {
  margin-right: 10px;
  font-size: 1.2rem;
}

.findTargetDomainsTableActiveItem {
  font-weight: 400;
}

.findTargetDomainsTableActiveItem > svg {
  color: #00265b;
}

.customBehaviorInput {
  margin: -4px 0 -4px 10px;
  width: 100%;
  border-radius: 4px;
  border: 1px #cfd8dc solid;
  padding: 3px;
  color: #455a64;
  outline: none;
  box-shadow: none;
}

.targetBehaviorPill {
  /*width: 50%;*/
  display: flex;
  align-items: center;
  background-color: #f0f4f7;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 4px rgba(32, 32, 32, 0.3);
}

.targetBehaviorPill > svg {
  margin-right: 20px;
}

.targetBehaviorBox {
  background-color: #f4f1fa;
  border-radius: 8px;
  padding: 10px;
  margin: 10px 0;
  box-shadow: 0 0 4px rgba(32, 32, 32, 0.3);
}

.nonReplacementBehaviorContainer {
  margin: 20px 0;
  padding: 10px;
  background-color: #f0f4f7;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.nonReplacementBehaviorContainer > svg {
  margin-right: 5px;
  font-size: 1.2rem;
}

.ghostImage {
  background-color: #4db6ac;
  color: #ffffff;
  border-radius: 8px;
  padding: 5px 15px;
  text-align: center;
  font-weight: 700;
  width: 200px;
  position: absolute;
  top: -1000px;
}

.implementationStepModal {
  background-color: #f7f4fc;
  box-shadow: 0 0 12px 4px rgb(120, 134, 141);
  border-radius: 4px;
  text-align: left;
}

.implementationStepModalTopContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.redBtnSm {
  border: 1px solid #d41b32;
  background-color: inherit;
  border-radius: 4px !important;
  padding: 5px 15px !important;
  color: #d41b32;
  font-weight: 700 !important;
  font-size: 0.9rem;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
}

/*.dropFileArea {*/
/*  width: auto;*/
/*  height: 200px;*/
/*  background-color: #f8f8f8;*/
/*  border: 2px dashed #979797;*/
/*  border-radius: 8px;*/
/*  margin-bottom: 20px;*/
/*}*/

.targetBehaviorSummarySvg {
  font-size: 1.5rem !important;
  margin: 0 !important;
}

.interventionGroupIcon {
}

.interventionGroupIcon circle,
.interventionGroupIcon text {
  fill: #a489d4 !important;
}

.interventionGroupsInfoTooltip {
  max-width: 60%;
  background-color: #ffffff;
  box-shadow: 0 0 8px 2px rgb(120, 134, 141);
  border-radius: 8px;
  padding: 10px 10px 0 10px;
  color: white;
  z-index: 1050;
}

.interventionGroupsInfoTooltipNav {
  display: flex;
  justify-content: flex-end;
}

.interventionGroupsInfoTooltipNav svg {
  margin: 0 10px;
  color: #e5e5e5;
  cursor: pointer;
}

.fbaInfoContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fbaUserInfoContainer {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}

.fbaUserInfoContainer span:first-child > svg {
  height: 1.6rem;
  width: 0.625em !important;
  cursor: pointer;
}

.fbaUserInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  white-space: pre;
}

.fbaSummaryBtn {
  border: none;
  background-color: #00265b;
  border-radius: 4px !important;
  padding: 5px 15px !important;
  color: #ffffff;
  font-weight: 600 !important;
  font-size: 0.9rem;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
}

.fbaSummaryBtn:hover {
  background-color: #3a3674;
  transition: background-color 0.4s;
  outline: none;
}

.fbaDeleteBtn {
  border: none;
  background-color: #880404;
  border-radius: 4px !important;
  padding: 5px 15px !important;
  color: #ffffff;
  font-weight: 600 !important;
  font-size: 0.9rem;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
}

.fbaDeleteBtn:hover {
  background-color: #995e5e;
  transition: background-color 0.4s;
  outline: none;
}

.fbaSummaryBtn > svg,
.fbaDeleteBtn > svg,
.interpretFbaBtn > svg {
  margin-right: 10px;
  color: #ffffff;
}

.svgMargin10 > svg {
  margin-right: 10px;
  color: #ffffff;
}

.interpretFbaBtn {
  border: none;
  background-color: #86bc47;
  border-radius: 4px !important;
  padding: 5px 15px !important;
  color: #ffffff;
  font-weight: 600 !important;
  font-size: 0.9rem;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
}

.interpretFbaBtn:hover {
  background-color: #71a848;
  transition: background-color 0.4s;
  outline: none;
}

.fbaTable,
.fbaChartTable,
.fbaIndObsTable {
  border-collapse: collapse;
  width: 100%;
  text-align: center;
}

.fbaTable thead tr {
  background-color: #78909c;
  color: #ffffff;
  border: none;
  text-transform: capitalize;
}

.fbaChartTable thead tr {
  background-color: #90a4ae;
  color: #ffffff;
  border: none;
  text-transform: capitalize;
}

.fbaTable tbody tr {
  background-color: #ffffff;
  color: #455a64;
}

.fbaChartTable tbody tr {
  background-color: #ffffff;
  color: #455a64;
}

.fbaChartTable td,
.fbaChartTable th {
  padding: 10px;
  border: 1px #cfd8dc solid;
}

.fbaTable td,
.fbaTable th {
  padding: 10px;
  border: 1px #cfd8dc solid;
}

.fba-item {
  display: flex;
  padding: 1rem;
  border-radius: 0.25rem;
  background: linear-gradient(#fdfdfd, #eaeaea);
  border: 1px solid lightgray;
}

.fba-item.new {
  background: #c9daf8;
}

.fba-item p {
  margin: 0;
  color: inherit;
}

.fba-item + .fba-item {
  margin-top: 0.5rem;
}

.fba-top {
  display: flex;
  flex-direction: column;
  border-radius: 0.25rem;
  flex-basis: 25%;
  height: auto;
  margin: 0 0.5rem;
}

.fba-top > div {
  padding: 0.5rem;
  /*color: white;*/
}

.fba-top > div:first-child {
  text-align: center;
  font-weight: bold;
  border-radius: 0.5rem 0.5rem 0 0;
  color: white;
}

.fba-top > div:last-child {
  text-align: center;
  border-radius: 0 0 0.5rem 0.5rem;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fba-top.antecedent > div:first-child {
  background-color: #9a1a00;
}

.fba-top.antecedent > div:last-child {
  background-color: #9a1a004c;
}

.fba-top.behavior > div:first-child {
  background-color: #216bbd;
}

.fba-top.behavior > div:last-child {
  background-color: #216bbd4c;
}

.fba-top.consequence > div:first-child {
  background-color: #267e29;
}

.fba-top.consequence > div:last-child {
  background-color: #267e294c;
}

.review-abc-data-table > thead > tr > th {
  vertical-align: middle;
}

.review-abc-data-table > thead > tr > th:not(.non-interactive):hover {
  cursor: pointer;
  color: #00265b;
  background: #f2f2f2;
}

.observed-behaviors-container {
  border-radius: 0.25rem;
  background: white;
  border: 1px solid lightgray;
  max-width: 30%;
}

.observed-behaviors-container > div {
  padding: 0.5rem;
}

.observed-behaviors-container > div:first-child {
  border-radius: 0.25rem 0.25rem 0 0;
  background-color: #00265b;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  min-height: 4rem;
}

.abc-item {
  padding: 0.5rem;
  border-radius: 0.25rem;
  background: linear-gradient(#fdfdfd, #eaeaea);
  border: 1px solid lightgray;
}

.abc-item p {
  margin: 0;
}

.abc-item + .abc-item {
  margin-top: 0.5rem;
}

.fba-observation-dialog {
  max-width: unset;
  margin-left: 2rem;
  margin-right: 2rem;
}

.fba-observation-dialog-content {
  display: flex;
  margin: -0.5rem;
}

.fba-observation-dialog-content > div {
  margin: 0.5rem;
}

.fba-observation-container {
  width: 100%;
  background: linear-gradient(#fdfdfd, #eaeaea);
  border: 1px solid lightgray;
  border-radius: 0.25rem;
}

.fba-observation-container > div {
  padding: 0.5rem;
}

.fba-observation-container > div:first-child {
  text-align: center;
  border-radius: 0.25rem 0.25rem 0 0;
}

.fba-observation-container .fba-header {
  flex-grow: 1;
}

.fba-observation-container.antecedent > div:first-child {
  background-color: #9a1a00;
}

.fba-observation-container.behavior > div:first-child {
  background-color: #216bbd;
}

.fba-observation-container.consequence > div:first-child {
  background-color: #267e29;
}

.fba-observation-container > div:first-child p,
.fba-observation-container > div:first-child h3 {
  color: white;
}

.fba-observation-container > div > div:first-of-type > svg {
  color: white;
  cursor: pointer;
  /*width: 0.625em;*/
}

.review-abc-behaviors {
  text-align: center;
  margin: 0 20%;
}

.review-abc-behaviors > div:first-child {
  color: white;
  background-color: #00265b;
  padding: 0.5rem;
  border-radius: 0.25rem 0.25rem 0 0;
}

.review-abc-behaviors > div:first-child > h3 {
  color: white;
}

.review-abc-behaviors > div:last-child {
  text-align: left;
  border: 1px solid lightgrey;
  background: linear-gradient(#fdfdfd, #eaeaea);
  padding: 0.5rem;
}

.review-abc-behaviors .behavior-item {
  cursor: pointer;
  padding: 0.5rem;
  background: white;
  border-radius: 0.5rem;
  border: 1px solid lightgrey;
  display: flex;
  align-items: center;
}

.review-abc-behaviors .behavior-item.active {
}

.review-abc-behaviors .behavior-item p {
  font-weight: 500;
}

.review-abc-behaviors .behavior-item:hover {
  border: 1px solid #00265b;
}

.review-abc-behaviors .behavior-item.active {
  border: 1px solid #00265b;
}

.review-abc-behaviors .behavior-item.active p {
  font-weight: bold;
}

.review-abc-behaviors .behavior-item.active svg,
.review-abc-behaviors .behavior-item:hover svg {
  color: #00265b;
}

.review-abc-behaviors .behavior-item svg {
  margin-right: 0.5rem;
}

.review-abc-behaviors .behavior-item + .behavior-item {
  margin-top: 0.5rem;
}

.abc-focus-container {
  background: linear-gradient(#fdfdfd, #eaeaea);
  font-weight: bold;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid lightgrey;
}

.selected-ac-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: -0.5rem;
}

.selected-ac-container > div {
  margin: 0.5rem;
}

.decision-tabs > nav {
  display: flex;
  justify-content: center;
  margin-bottom: -21px;
  border: none;
}

.decision-tabs > nav > a {
  margin-bottom: 0 !important;
  background: white;
  border-color: lightgrey !important;
  border-radius: 0 !important;
  color: inherit !important;
}

.decision-tabs > nav > a:first-child {
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

.decision-tabs > nav > a:last-child {
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.decision-tabs > nav > a.active {
  font-weight: bold;
  color: white !important;
  background: #00265b !important;
}

.decision-tabs > nav > a + a {
  margin-left: -1px;
}

.decision-tabs > .tab-content {
  padding: 2rem 1rem 1rem 1rem;
  border-radius: 0.5rem;
  border: 1px solid lightgrey;
}

.abc-row svg {
  color: #00265b;
  cursor: pointer;
}

.abc-row > .hovered {
  cursor: pointer;
  background-color: #216bbd4c;
}

.smart-goal-container {
  border: 1px solid lightgrey;
}

.smart-goal-container > div:first-child {
  padding: 1rem;
  background-color: #90a4ae;
}

.smart-goal-container > div:first-child > h3 {
  color: white;
}

.smart-goal-container > div:last-child {
  background-color: #eff4f7;
}

.smart-goal-container > div:last-child > div {
  padding: 1rem;
}

.smart-goal-container > div:last-child > div:first-child {
  border-right: 1px solid lightgrey;
  flex-basis: 40%;
}

.smart-goal-container > div:last-child > div:first-child > .behaviorsArea {
  background-color: white;
}

.coachDashboardSidebar {
  min-width: 265px;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 10px;
  align-self: flex-start;
}

.coachDashboardSidebar h4 {
  padding: 10px 20px;
}

.coachDashboardSidebar .nav-item {
  white-space: nowrap;
}

.coachDashboardUserInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  /*padding-top: 20px;*/
}

.coachDashboardUserInfo > img {
  margin: 20px;
}

.groupsSidebarTab {
  width: 100%;
}

.coachDashboardSidebar .nav-link {
  color: #00265b;
  padding: 5px 20px;
}

.coachDashboardSidebar .nav-link svg {
  width: 1rem;
  color: #00265b;
}

.coachDashboardSidebar .nav-link.active {
  color: #00265b;
  background-color: rgb(255, 171, 0);
  font-weight: bold;
  border-radius: 4px;
}

.popover .coachDashboardSidebar {
  min-width: unset;
  background-color: unset;
  border-radius: unset;
  padding: unset;
}

.popover .coachDashboardSidebar h4 {
  padding: unset;
}

.sortingOptionsGroup {
  position: relative;
  display: inline-flex;
  vertical-align: center;
}

.sortingOptionsGroup > button {
  color: #455a64;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  padding: 5px;
}

.sortingOptionsGroup > button:hover {
  color: #455a64;
  background-color: #cce5ff;
  border: 1px solid #cccccc;
  padding: 5px;
}

.sortingOptionsGroup > button.active {
  color: #ffffff;
  background-color: #00265b;
  font-weight: 600 !important;
}

.sortingOptionsGroup > button.active > svg {
  color: #ffffff;
}

.sortingOptionsGroup > button:first-child {
  border-radius: 4px 0 0 4px;
}

.sortingOptionsGroup > button:last-child {
  border-radius: 0 4px 4px 0;
}

.assigmentCollapseHeader {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.assigmentCollapseHeader span > svg {
  width: 0.625em !important;
}

.assigmentCollapseHeader:hover {
  cursor: pointer;
}

.newCaseBtn {
  border: 1px #00265b solid;
  background-color: #f8fbff;
  border-radius: 20px;
  padding: 5px 10px;
  color: #00265b;
  font-weight: bold;
  font-size: 1rem !important;
  font-family: "Roboto", sans-serif;
  text-transform: capitalize;
  text-align: center;
  cursor: pointer;
  display: inline-block;
}

.newCaseBtn > svg {
  color: #2a74d1;
  margin-right: 0.5rem;
}

.newCaseBtn:hover,
.newCaseBtn:focus {
  background-color: #358bff80;
  transition: background-color 0.4s;
  outline: none;
}

.assigmentInfo {
}

.assigmentInfo .active {
  border: 2px solid #ff9800;
  background: #ffefd8;
}

.assigmentInfo.list > div:first-child {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.assigmentInfo.list > div:last-of-type {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.assigmentInfo.grid {
  display: flex;
  flex-wrap: wrap;
  margin: -0.5rem;
  justify-content: flex-start;
  align-items: flex-start;
}

.interventionInfoContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 8px;
  margin: 0 15%;
  cursor: default;
}

.interventionGroupItem {
  background-color: #ffffff;
  padding: 10px;
  border-bottom: 2px solid #f0f4f7;
}

.interventionGroupItem .changeGroupIcons {
  display: none;
}

.interventionGroupTitle:hover {
  color: #00265b;
  cursor: pointer;
  text-decoration: underline;
}

.intervention-group-grid-item {
  background-color: #ffffff;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc(25% - 1rem);
  /*min-width: 215px;*/
  max-width: 400px;
  margin: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid lightgray;
}

.intervention-group-grid-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem 0.5rem 0 0;
  padding: 0.5rem;
  background: #00265b;
  min-height: 3.25rem;
  max-height: 3.25rem;
}

.intervention-group-grid-item-header.sample {
  background: #ffab00;
  color: #00265b;
}

.intervention-group-grid-item-header.sample svg,
.intervention-group-grid-item-header.sample .editable-name > h3 {
  color: #00265b !important;
}

.intervention-group-grid-item-header svg {
  color: white;
}

.intervention-group-grid-item-header .editable-name {
  overflow: auto;
  display: inline-flex;
  align-items: center;
}

.intervention-group-grid-item-header .editable-name > div {
  margin-left: 0.5rem;
}

.intervention-group-grid-item-header .editable-name > h3 {
  color: rgb(255, 255, 255);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.intervention-group-grid-item-body {
  padding: 0.5rem;
}

.intervention-group-grid-item-body .last-activity {
  text-align: center;
  flex-grow: 2;
}

.intervention-group-grid-item-body .fidelity-chart-area .last-activity,
.intervention-group-grid-item-body .details-link {
  display: none;
}

.intervention-group-grid-item-body .fidelity-chart-area #monitoring-panel {
  justify-content: center;
}

.intervention-group-grid-item-body h5.section:first-child {
  margin-top: -0.5rem;
}

.intervention-group-grid-item-body h5.section,
.groupRecommendationCardBody h5.section {
  font-weight: bold;
  text-transform: uppercase;
  margin: 0 -0.5rem;
  padding: 0.25rem 0.5rem;
  background-color: #fff2e1;
}

.customCheckboxContainer {
  left: 10px;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.customCheckboxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.customCheckboxContainer + .fixed {
  left: 0;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
}

.customRadioCheckmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #ffffff;
  border: 1px solid #b8c5cb;
  border-radius: 50%;
}

.customCheckboxCheckmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #ffffff;
  border: 1px solid #b8c5cb;
  border-radius: 4px;
}

.customCheckboxContainer:hover input ~ .customRadioCheckmark,
.customCheckboxContainer:hover input ~ .customCheckboxCheckmark {
  background-color: #ccc;
}

.customCheckboxContainer input:checked ~ .customRadioCheckmark {
  background-color: #ffffff;
}

.customCheckboxContainer input:checked ~ .customCheckboxCheckmark {
  background-color: #00265b;
}

.customRadioCheckmark:after,
.customCheckboxCheckmark:after {
  content: "";
  position: absolute;
  display: none;
}

.customCheckboxContainer input:checked ~ .customRadioCheckmark:after,
.customCheckboxContainer input:checked ~ .customCheckboxCheckmark:after {
  display: block;
}

.customCheckboxContainer .customRadioCheckmark:after {
  top: 4px;
  left: 4px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #00265b;
}

.customCheckboxContainer .customCheckboxCheckmark:after {
  left: 6px;
  top: 2px;
  width: 10px;
  height: 15px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.scrollableTable {
  display: block;
  empty-cells: show;
}

.interviewStudentsTable,
.fbaStudentsTable {
  border: 1px solid #bcc8cf !important;
  display: block;
  empty-cells: show;
}

.fbaStudentsTableTeacherCell {
  padding: 0 !important;
}

.fbaStudentsTableTeacherCell > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom: 1px solid #bcc8cf;
}

.fbaStudentsTableTeacherCell > div:last-child {
  border-bottom: none;
}

.scrollableTable thead,
.fbaStudentsTable thead,
.interviewStudentsTable thead {
  position: relative;
  display: block;
  width: 100%;
}

.scrollableTable thead tr,
.interviewStudentsTable thead tr {
  background-color: #78909c;
  color: #ffffff;
  border: none;
  text-transform: uppercase;
  width: 100%;
  display: flex;
}

.fbaStudentsTable thead tr {
  background-color: #90a4ae;
  color: #ffffff;
  border: none;
  text-transform: capitalize;
  width: 100%;
  display: flex;
}

.scrollableTable tbody,
.fbaStudentsTable tbody,
.interviewStudentsTable tbody {
  display: block;
  position: relative;
  width: 100%;
  overflow-y: auto;
  max-height: 300px;
}

.scrollableTable tbody tr {
  background-color: #f0f4f7;
  color: #455a64;
  border-top: 3px #ffffff solid;
  border-bottom: 3px #ffffff solid;
  width: 100%;
  display: flex;
  cursor: pointer;
}

.fbaStudentsTable tbody tr {
  background-color: #ffffff;
  color: #455a64;
  width: 100%;
  display: flex;
  font-weight: bold;
}

.interviewStudentsTable tbody tr {
  background-color: #f0f4f7;
  color: #455a64;
  width: 100%;
  display: flex;
  cursor: pointer;
}

.interviewStudentsTable tr,
.fbaStudentsTable tr {
  border-bottom: 1px solid #bcc8cf !important;
}

.scrollableTable td,
.scrollableTable th {
  flex-basis: 100%;
  flex-grow: 2;
  display: block;
  padding: 10px;
}

.interviewStudentsTable td,
.interviewStudentsTable th,
.fbaStudentsTable td,
.fbaStudentsTable th {
  flex-basis: 100%;
  flex-grow: 2;
  display: block;
  padding: 10px 20px;
}

.fbaStudentsTable td:first-child,
.fbaStudentsTable th:first-child {
  flex-basis: 40%;
  flex-grow: 1;
  border-right: 1px solid #bcc8cf !important;
}

.scrollableTable td:first-child,
.scrollableTable th:first-child {
  flex-basis: 10%;
  flex-grow: 1;
}

.interviewStudentsTable td:last-child,
.interviewStudentsTable th:last-child {
  flex-basis: 40%;
  flex-grow: 1;
  text-align: right;
}

.interviewStudentsTable .activeStudentRow {
  background-color: #00265b;
  color: #ffffff;
}

.rosterScrollableTable td:first-child,
.rosterScrollableTable th:first-child {
  flex-basis: 100%;
  flex-grow: 1;
}

.interviewStudentsTable .activeStudentRow > td:first-child {
  font-weight: bold;
}

.interviewStudentsTable .activeStudentRow > td:last-child {
  font-style: italic;
}

.userInfoModalTitle {
  display: flex;
  align-items: center;
  position: absolute;
}

.userInfoModalTitle > img {
  margin-right: 10px;
}

/*overwrite bootstrap styles*/
.interviewListGroup h4 {
  color: inherit;
  font-weight: bold;
  margin-bottom: 0;
}

.interviewListGroup p {
  color: inherit;
  margin-bottom: 0;
  font-size: small;
}

.interviewListGroup .list-group-item {
  background-color: rgb(255, 171, 0);
  color: #00265b;
}

.interviewListGroup .list-group-item.active {
  background-color: #00265b;
  color: #ffffff;
  border-color: black;
}

.interviewListGroup .list-group-item:first-child {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.interviewListGroup .list-group-item:last-child {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.thinkLabel {
  display: flex;
  align-items: center;
}

.thinkLabel > svg {
  color: inherit;
  margin-right: 5px;
}

.exampleCollapseContainer,
.observedItemsContainer {
  background-color: #f4f4f4 !important;
  border: 1px solid rgb(255, 171, 0);
  padding: 10px 20px;
  margin-bottom: 20px;
  border-radius: 4px;
}

.observedItemsSmallContainer > .observedInterventionItemContainer {
  padding: 5px;
}

.observedItemsSmallContainer > .observedInterventionItemContainer:last-of-type {
  border-bottom: unset;
}

.exampleCollapseContainer > div > div:first-of-type > svg {
  width: 0.625em;
}

.thinkTabExampleContainer {
  font-size: small;
}

.thinkTabExampleContainer > ol {
  padding-left: 20px;
}

.thinkTabExampleContainer > ol > li {
  margin-bottom: 10px;
}

.thinkTabExampleContainer ul > li {
  font-style: italic;
}

.loadImageSpinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
}

.selectedStudentRow {
  background-color: #dbdbdb !important;
}

.selectedStudentRow > button {
  color: #455a64;
  background-color: #ffffff !important;
}

.fbaTimer {
  font-size: 1.75rem;
  font-weight: 700;
  width: fit-content;
  padding: 10px 30px;
  color: #00265b;
  border: 1px solid #00265b;
  border-radius: 8px;
  background-color: rgb(255, 171, 0);
}

.fbaABCContainer {
  background-color: #f4f4f4;
  border: 1px solid #cfd8dc;
  padding: 20px 20px 0px 20px;
  margin: 10px 0;
  border-radius: 12px;
  flex-grow: 1;
}

.faWhiteBackground {
  background-color: #ffffff;
  border-radius: 100%;
}

.fbaABCTable {
  display: block;
  empty-cells: show;
}

.fbaABCTable thead {
  position: relative;
  display: block;
  width: 100%;
}

.fbaABCTable thead tr {
  background-color: #90a4ae;
  color: #ffffff;
  border: none;
  text-transform: capitalize;
  width: 100%;
  display: flex;
}

.fbaABCTable tbody {
  display: block;
  position: relative;
  width: 100%;
  overflow-y: auto;
  max-height: 300px;
}

.fbaABCTable tbody tr {
  background-color: #ffffff;
  color: #455a64;
  width: 100%;
  display: flex;
  border-bottom: 1px solid #bcc8cf !important;
}

.fbaABCTable td,
.fbaABCTable th {
  border-left: 1px solid #bcc8cf !important;
  border-right: 1px solid #bcc8cf !important;
  text-align: center;
  flex-basis: 100%;
  flex-grow: 2;
  display: block;
  padding: 10px 20px;
}

.fbaExcellentMsgContainer {
  display: flex;
  align-items: center;
  background: #f0f4f7;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.28);
  border-radius: 8px;
  padding: 20px;
  width: inherit;
  max-width: 80%;
}

.fbaExcellentMsgContainer > svg {
  margin-right: 20px;
}

.chevronDropDownBtn {
  opacity: unset !important;
  left: 0 !important;
  top: 0 !important;
}

.groupInfoModalTitle {
  display: flex;
  align-items: center;
}

.groupInfoModalTitle > img {
  margin-right: 10px;
}

.groupInfoModalTitle h4 {
  color: #ffffff;
}

.whiteSplitBtnToggle .whiteBtnSm {
  border: none;
  box-shadow: none;
}

.whiteSplitBtnToggle .whiteBtnSm:first-child {
  padding: 5px 40px;
}

.whiteSplitBtnToggle .whiteBtnSm:last-child {
  border-bottom-left-radius: unset;
  border-top-left-radius: unset;
  border-left: 1px solid #b2bfc6;
}

.defaultDropDown .dropdown-menu {
  top: 100% !important;
  left: auto !important;
}

.praiseItemContainer {
  display: flex;
  align-items: center;
  background-color: #efeaf8;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 12px;
}

.praiseItemContainer > span {
  margin-right: 10px;
}

.praiseItemContainer > div {
  left: 0;
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.planningNotesContainer {
  border-radius: 4px;
  border: 1px solid black;
  padding: 10px;
  background-color: rgb(255, 171, 0);
}

.interventionSelectContainer {
  background-color: #8561c5;
  border-radius: 4px;
  padding: 10px 40px;
}

.interventionSelectContainer > span > svg {
  background-color: white;
  border-radius: 100%;
  color: #8561c5;
  margin: 0 -20px 0 15px;
  font-size: unset;
  width: 1.2rem !important;
  height: 1.2rem;
}

.interventionStudentsContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.interventionStudentsContainer > div {
  flex-basis: 45%;
  text-align: center;
}

.interventionStudentsContainer > .studentGroupCell {
  background: #ffffff;
}

.moreStudentsContainer {
  color: #ffffff;
  background-color: #b0bec5;
  margin: 5px;
  border-radius: 4px;
  font-weight: bold;
  padding: 0 5px;
  cursor: pointer;
}

.notesListContainer {
  border: 1px solid black;
  border-radius: 4px;
  height: 300px;
  overflow-y: auto;
}

.notesListContainer .list-group-item {
  border: none;
  margin-bottom: 0;
  border-bottom: 1px solid black;
  background-color: #ffffff;
}

.notesListContainer .list-group-item.active {
  background-color: #d1c3e9;
  /*border-color: unset;*/
}

.notesListContainer::-webkit-scrollbar {
  width: 10px;
}

.notesListContainer::-webkit-scrollbar-track {
  background: #d8d8d8;
}

.notesListContainer::-webkit-scrollbar-thumb {
  background: #00265b;
}

.greyContainer {
  background-color: #f0f4f7;
  border: 1px solid #ededed;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  padding: 10px;
}

.interventionSupportImplContainer {
  width: fit-content;
  margin-left: 20px;
  margin-top: -24px;
  /*margin-bottom: 10px;*/
  background-color: #ff9800;
  border-radius: 20px;
  color: #ffffff;
  padding: 5px 40px;
  font-size: small;
  cursor: pointer;
}

.customDefaultCheckbox {
  min-width: 1.2rem;
  min-height: 1.2rem;
  margin-right: 10px;
}

.customDefaultCheckbox > label::before {
  border-radius: unset;
  border: 1px solid #cfd8dc;
}

.customDefaultCheckbox > label::before,
.customDefaultCheckbox > label::after {
  top: 0.125rem;
  width: 1.2rem;
  height: 1.2rem;
}

.customMeetingCheckbox {
  padding-left: 0;
}

.customMeetingCheckbox > label {
  position: relative;
  padding-right: 1.5rem;
  font-size: small;
  font-weight: bold;
}

.customMeetingCheckbox > label::before,
.customMeetingCheckbox > label::after {
  top: 0.1rem;
  right: 0;
  left: auto;
}

.observedItemCheckbox {
  min-width: 1.6rem;
  min-height: 1.6rem;
  margin-right: 10px;
}

.observedItemCheckbox > label::before {
  border-radius: unset;
  border: 2px solid #cfd8dc;
}

.observedItemCheckbox > label::before,
.observedItemCheckbox > label::after {
  top: 0;
  width: 1.6rem;
  height: 1.6rem;
}

.notPlannedItemCheckbox {
  min-width: 1.6rem;
  min-height: 1.6rem;
  margin-right: 10px;
}

.notPlannedItemCheckbox > label::before {
  border-radius: unset;
  border: 2px solid #cfd8dc;
}

.notPlannedItemCheckbox > label::before,
.notPlannedItemCheckbox > label::after {
  top: 0;
  width: 1.6rem;
  height: 1.6rem;
}

.recordGoalProgressTable {
  border-collapse: collapse;
  table-layout: auto;
  width: 100%;
}

.recordGoalProgressTable,
.recordGoalProgressTable th,
.recordGoalProgressTable td {
  border: 1px solid #cfd8dc;
}

.recordGoalProgressTable th,
.recordGoalProgressTable td {
  padding: 15px 20px;
}

.recordGoalProgressTable thead tr {
  background-color: #90a4ae;
  color: #ffffff;
}

.recordGoalProgressTable tbody tr {
  color: #455a64;
}

.recordGoalProgressTable tbody tr:nth-child(odd) {
  background-color: #ffffff;
}

.recordGoalProgressTable tbody tr:nth-child(even) {
  background-color: #f0f4f7;
}

.centerModalHeader > :nth-child(1) {
  flex: 1;
  text-align: left;
}

.centerModalHeader > :nth-child(2) {
  flex: 2;
  text-align: center;
}

.centerModalHeader > :nth-child(3) {
  flex: 1;
  text-align: right;
}

.centerModalHeader .interventionSelectContainer {
  width: fit-content;
  margin: 0 auto;
}

.modal-header .close:focus {
  outline: none;
}

.observedInterventionItemContainer {
  display: flex;
  align-items: center;
  padding: 20px;
  border-bottom: 1px dashed #bec9ce;
}

.observedMoreSteps {
  text-align: center;
  margin: -10px -20px;
  padding-bottom: 10px;
  cursor: pointer;
  background-image: linear-gradient(to bottom, transparent, #adadad);
}

.modifyInterventionContainer {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.modifyInterventionContainer > button {
  margin: 0 10px;
}

.rosterTab .nav-link {
  color: #00265b;
  padding: 5px 10px;
  border-radius: 4px;
}

.rosterTab .nav-link.active {
  font-weight: 700;
  background-color: #f4ae3d;
}

.rosterTab.nav-item {
  display: inline-block;
}

.fidelityContainer {
  flex-grow: 2;
  text-align: center;
}

.fidelityContainer > div:nth-child(1) {
  font-size: xx-large;
  color: #8bc34a;
}

.fidelityContainer > div:nth-child(2) {
  font-size: small;
}

.fidelityContainer > div:nth-child(3) {
  font-size: small;
  color: #2a74d1;
}

.fidelityContainer > div:nth-child(3) > svg:first-child {
  border-left: 1px solid #2a74d1;
  color: #2a74d1;
  margin-right: 4px;
}

.fidelityContainer > div:nth-child(3) > svg:last-child {
  border-right: 1px solid #2a74d1;
  color: #2a74d1;
  margin-right: 4px;
}

.welcomeContainer {
  background: linear-gradient(
    90deg,
    rgba(0, 39, 91, 1) 0%,
    rgba(0, 199, 248, 1) 100%
  );
  padding: 20px;
  color: white;
  border-radius: 12px;
}

.welcomeContainer h1,
.welcomeContainer h2,
.welcomeContainer h3,
.welcomeContainer h4 {
  color: #ffffff;
}

.readyCoachLabelH1 {
  font-size: 2.5rem !important;
  font-weight: 600;
}

.interiorDataContainer {
  padding: 40px;
  margin: 0;
}

.coachDashboardContentContainer {
  flex: 1;
  width: 0;
  /*margin-left: 40px;*/
}

.staticAssessmentRow {
  font-weight: bold;
  padding: 10px 20px;
  background-color: #90a4ae;
  color: #ffffff;
  border: none;
  text-transform: uppercase;
}

.staticMeasurementBody > div {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px 20px;
}

.staticMeasurementBody > div > svg {
  margin-left: 10px;
  margin-right: 10px;
}

.staticMeasurementBody > div:nth-child(odd) {
  background-color: #ffffff;
  color: #455a64;
  border: none;
}

.staticMeasurementBody > div:nth-child(even) {
  background-color: #f0f4f7;
  color: #455a64;
  border: none;
}

.behaviorDataCell {
  background-color: #d2e0e7;
  border: 1px #484848 solid;
  border-radius: 8px;
  height: 45px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /*margin-top: -5px;*/
  text-overflow: ellipsis;
  overflow: hidden;
}

.behaviorsArea {
  max-height: 200px;
  overflow-y: auto;
  padding: 5px;
  /*min-height: 100px;*/
  display: flex;
  flex-flow: wrap;
  border-radius: 4px;
  border: 1px dashed #acbbc3;
}

.behaviorsAreaItem {
  margin: 5px;
  padding: 0 10px;
  background-color: #f8f8f8;
  border: 1px solid #acbbc3;
  font-family: monospace;
  border-radius: 4px;
  height: min-content;
}

.assessmentOptionsHeader {
  background: #78909c;
  color: #ffffff;
  padding: 15px 70px;
}

.assessmentOption {
  background: #eeeeef;
  color: #707070;
  padding: 10px 20px;
  margin: 5px 0;
}

.assessmentOption:hover,
.assessmentOption:focus {
  background-color: rgb(255, 171, 0);
  transition: background-color 0.2s;
  outline: none;
  cursor: pointer;
}

.noStaffContainer {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 12px;
}

.noStaffContainer > div {
  background-color: #f0f4f7;
  text-align: center;
  padding: 60px;
  border-radius: 12px;
}

.manageDistrictContainer {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 12px;
}

.district-summary-quadrant {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: -0.5rem;
}

.district-summary-quadrant > .district-summary-chart-container {
  margin: 0.5rem;
  flex: 1;
  flex-basis: calc(50% - 1rem);
  width: calc(50% - 1rem);
}

.district-summary-quadrant > .district-summary-chart-container > div {
  height: 250px;
}

.district-sections-toggle-buttons {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: -1rem;
  align-items: center;
  justify-content: center;
}

.district-sections-toggle-buttons > button {
  margin: 1rem;
  min-width: 16rem;
}

.district-summary-chart-container {
  position: relative;
  padding: 0.5rem;
  border: 1px solid #90a4ae;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  margin-top: 10px;
  min-height: 250px;
}

.district-summary-filters {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  justify-content: flex-end;
  margin: -0.5rem -1rem;
}

.district-summary-filters > * {
  margin: 0.5rem 1rem;
  height: 2.25rem;
}

.district-summary-filters > div:nth-child(3),
.district-summary-filters > div:nth-child(4) {
  flex: 1 1 calc(25% - 2rem);
  min-width: 17rem;
}

.district-summary-filters > div:first-child,
.district-summary-filters > div:first-child + div {
  flex: 1 1 calc(50% - 2rem) !important;
}

.blueTitle {
  color: #1e88e5 !important;
  font-weight: bold;
}

.blueTitle > svg {
  color: #1e88e5;
}

.blueLink {
  color: #007bff !important;
}

.text-underline {
  text-decoration: underline !important;
}

.blueLink:hover {
  color: #00265b !important;
  cursor: pointer;
  text-decoration: underline;
}

.withInnerWhiteSvg > svg {
  color: #ffffff;
  margin-right: 10px;
}

.noModerationMessageContainer {
  background-color: #f6f6f6;
  border: 1px solid #e1e4e6;
  text-align: center;
  padding: 40px 0;
}

.assessmentInfoContainer {
  background-color: #f0f4f7;
  padding: 20px;
  display: flex;
}

.assessmentInfoContainer > div:first-of-type {
  flex-grow: 3;
}

.assessmentInfoContainer > div:last-of-type {
  flex-grow: 2;
}

.measurementLabel h4 {
  display: inline-block;
  padding: 10px 0;
  font-weight: bold;
}

.measurementLabel h4:nth-of-type(1) {
  width: 200px;
}

.measurementLabel h4:nth-of-type(2) {
  width: 100px;
  text-align: center;
}

.measurementLabel h4:nth-of-type(3) {
  width: 150px;
  text-align: center;
}

.measurementSelection {
  display: inline-block;
  border: 1px #b0bec5 solid;
}

.measurementSelection p {
  margin: 0;
  background-color: #f0f4f7;
  color: #2a74d1;
  padding: 2px 0;
}

.typeSelection {
  display: inline-block;
  width: 200px;
  padding: 10px;
  vertical-align: bottom;
}

.typeSelection > .form-control {
  height: 28px;
  padding: 2px;
}

.rangeSelection {
  cursor: pointer;
  display: inline-block;
  padding: 10px;
  width: 100px;
  text-align: center;
}

.colorSelection {
  cursor: pointer;
  display: inline-block;
  padding: 10px;
  width: 150px;
  text-align: center;
}

.uploadFile {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 40px;
  border-radius: 8px;
  background-color: #f0f4f7;
  box-shadow: 0 0 4px rgba(32, 32, 32, 0.3);
}

.inputMeasurementTable {
  font-family: "Montserrat", sans-serif;
}

.inputMeasurementTable thead th {
  border-bottom: unset;
  border-top: unset;
  color: #546e7a;
}

.inputMeasurementTable tbody tr {
  border: 2px solid #cfd8dc;
}

.inputMeasurementTable tbody tr td {
  padding: 5px;
}

.meetingNoteActive {
  background-color: #2196f345 !important;
}

.fidelityLoggedDataChart {
  margin: 0 10px;
  background-color: #f5f5f5;
  border: 1px solid #e2e4e3;
  min-height: 70px;
  min-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fidelityLoggedDataChart > canvas:hover {
  cursor: pointer;
}

.fidelityLoggedDataChartMini {
  padding: 2px;
}

.fidelity-chat-switches-panel {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0.5rem -1rem -0.125rem;
}

.custom-control {
  z-index: unset;
}

.fidelity-chat-switches-panel > .custom-switch {
  margin: 0.125rem 1rem;
}

.fidelity-chat-switches-panel > .custom-switch .custom-control-label:before {
  top: 0.125rem;
  left: -2.25rem;
  height: 1.25rem;
  /*height: 1rem;*/
  width: 2rem;
  pointer-events: all;
  border-radius: 0.625rem;
}

.fidelity-chat-switches-panel > .custom-switch .custom-control-label:after {
  top: calc(0.125rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1.25rem - 4px);
  height: calc(1.25rem - 4px);
  /*height: calc(1rem - 4px);*/
}

/*
 * Badges
 */

.badgesHeader {
  background-color: rgb(255, 171, 0);
  color: #00265b;
  padding: 20px;
  font-size: 20pt;
  font-weight: bolder;
  text-align: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.badgesContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.badgeCard {
  width: 320px;
  background-color: #f8f9fa;
  padding: 20px;
  margin: 20px;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
}

.badgeCard .badgeIcon {
  width: 200px;
  min-height: 100px;
  height: auto;
  margin: 8px;
}

.badgeCard .badgeIconIncomplete {
  opacity: 0.4;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.badgeCard .badgeName {
  font-weight: bold;
  font-size: 16pt;
}

.badgeCard p {
  font-size: 14pt;
}

/*
 * Navigation
 */

.chevronBack:hover {
  opacity: 0.8;
}

/*
 Notifications
 */
.notificationsContainer {
  max-height: 400px !important;
  overflow-y: auto;
  padding: 0;
}

.notificationsContainer > div > div {
  top: -2px;
  border-bottom: 2px solid #ebebeb;
  padding: 0.5rem 0.75rem;
}

.notificationsContainer > div > .unread {
  background-color: #d0f0ff;
  cursor: pointer;
}

/*
 Skill Focus Modal
 */

.skillFocusArea {
  padding: 5px;
  min-height: 100px;
  display: flex;
  flex-flow: wrap;
  border-radius: 4px;
  border: 1px dashed #acbbc3;
}

.skillFocusArea span {
  padding: 30px;
  width: 100%;
  text-align: center;
}

.specificButton {
  margin: 5px;
  padding: 0 10px;
  background-color: #f8f8f8;
  border: 1px solid #acbbc3;
  font-family: monospace;
  border-radius: 4px;
  height: min-content;
}

.needsAreaDataCell {
  line-height: normal;
  color: #0f2b31;
}

.needsAreaItem {
  display: inline-block;
  width: 295px;
  text-align: start;
  margin: 2px;
  padding: 2px 5px;
  background-color: #d4eefe;
  border-radius: 4px;
}

.needsAreaItemPopOver {
  display: inline-block;
  width: 180px;
  text-align: start;
  margin: 2px;
  padding: 2px 10px;
  background-color: #d4eefe;
  border-radius: 4px;
}

.needsAreaItemSkills {
  display: inline-block;
  margin: 5px;
  padding: 5px 10px;
  background-color: #d4eefe;
  border-radius: 4px;
}

.diagnosticInfoDataCell {
  line-height: normal;
  text-align: start;
}

.diagnosticInfoItem {
  display: inline-block;
  margin: 5px;
  padding: 5px 10px;
  background-color: #f8f8f8;
  border: 1px solid #acbbc3;
  font-family: monospace;
  border-radius: 4px;
}

.skillFocusPopoverItem {
  display: inline-block;
  margin: 5px;
  padding: 5px 10px;
  background-color: #f8f8f8;
  border: 1px solid #acbbc3;
  font-family: monospace;
  border-radius: 4px;
}

.skillFocusTabArea {
  margin: 20px -10px;
}

.skillFocusButton {
  text-align: center;
}

.skillFocusTab {
  border-radius: 0;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  box-shadow: none;
  flex-wrap: nowrap;
  max-height: 200px;
  overflow-y: auto;
}

.skillFocusTab.nav {
  flex-wrap: nowrap;
}

.skillFocusTab::-webkit-scrollbar {
  width: 10px;
}

.skillFocusTab::-webkit-scrollbar-track {
  background: #d8d8d8;
}

.skillFocusTab::-webkit-scrollbar-thumb {
  background: #e65101;
}

.skillFocusItem {
  border-top: 1px solid #a4a4a4 !important;
}

.skillFocusItem div {
  justify-content: flex-start !important;
}

.skillFocusItem:last-of-type {
  border-bottom: 1px solid #a4a4a4 !important;
}

.skillFocusItem .nav-link {
  background-color: #ffffff;
  height: 100% !important;
  border-radius: 0 !important;
}

.skillFocusItem .nav-link.active {
  background-color: #fde9cd;
  border-radius: 0;
  color: #585858;
}

.skillFocusSpecific {
  border-top: 1px solid #a4a4a4;
  cursor: pointer;
}

.skillFocusSpecificActive {
  background-color: #fde9cd;
  border-top: 1px solid #a4a4a4;
  padding: 5px;
  color: #585858;
  cursor: pointer;
}

.skillFocus {
  justify-content: flex-start;
}

.skillFocusActive {
  justify-content: flex-start;
  background-color: #fde9cd;
  color: #585858;
}

.skillFocusSpecific:last-of-type,
.skillFocusSpecificActive:last-of-type {
  border-bottom: 1px solid #a4a4a4;
}

/*
 Skill Focus Modal End
 */

/*
  Groups in Progress [Start]
 */

.recommendationCollapsibleHeader {
  display: inline-flex;
  align-items: baseline;
  cursor: pointer;
  width: max-content;
}

.recommendationCollapsibleHeader > h2 {
  margin-right: 10px;
}

.recommendationCollapsibleHeader > svg {
  font-size: large;
  color: #525252;
}

.yourNewGroupContainer {
  margin-bottom: 20px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 12px;
}

.recommendationsContainer {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
}

.recommendationsGroupList {
  display: flex;
  flex-flow: wrap;
  width: 100%;
  justify-content: center;
}

.recommendationsGroupList > .groupRecommendationCard {
  flex: 1 1 calc(25% - 10px);
  min-width: 215px;
  max-width: 350px;
}

.groupRecommendationCard {
  width: 400px;
  margin: 0.5rem;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-start;
  border-radius: 0.5rem;
  border: 1px solid lightgray;
}

.intervention-group-grid-item.highlighted,
.groupRecommendationCard.highlighted {
  box-shadow: 0 0 1rem rgb(0, 38, 91);
  background: #fff2e1;
}

.groupRecommendationCardHeader {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  color: white;
  padding: 10px;
  align-items: center;
  border-radius: 0.5rem 0.5rem 0 0;
  min-height: 3.25rem !important;
  max-height: 3.25rem !important;
}

.recommendationsContainer .groupRecommendationCardHeader,
.unfinished-groups-container .groupRecommendationCardHeader.recommended {
  background-color: #616161;
  flex: 1 1 100%;
}

.yourNewGroupContainer .groupRecommendationCardHeader,
.assigmentInfo .groupRecommendationCardHeader,
.unfinished-groups-container .groupRecommendationCardHeader.unfinished {
  background-color: #43a047;
  flex: 1 1 100%;
}

.groupRecommendationName {
  max-width: 55%;
  margin-right: 5px;
  display: inline-flex;
  align-items: center;
}

.groupRecommendationName > div {
  margin-left: 5px;
}

.groupRecommendationName > h3 {
  color: rgb(255, 255, 255);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.groupRecommendationName > input {
  /*width: 150px;*/
}

.groupRecommendationCardBody {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid lightgray;
}

.groupRecommendationCardBody > div > * {
  padding: 0.25rem 0.5rem;
}

.groupRecommendationCardBody > div > h5 {
  background-color: #fff2e1;
}

.groupRecommendationCardBody > div > form {
  padding: 0;
}

.groupRecommendationCardBody > div > form > * {
  padding: 0.25rem 0.5rem;
}

.groupRecommendationCardBody > div > form > h5 {
  background-color: #fff2e1;
}

.focus-section-tabs {
  display: flex;
  justify-content: center;
  border: none;
  margin: 0.25rem 0 0 0;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.focus-section-tabs > a {
  flex: 1;
  text-align: center;
  padding: 0;
  margin-bottom: 0 !important;
  background: white;
  border-color: lightgrey !important;
  border-radius: 0 !important;
  color: inherit !important;
}

.focus-section-tabs > a:first-child {
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

.focus-section-tabs > a:last-child {
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.focus-section-tabs > a.active {
  font-weight: bold;
  background: #fff2e1 !important;
}

.focus-section-tabs > a + a {
  margin-left: -1px;
}

.focuses-matrix-table {
  margin-bottom: 0.25rem;
}

.focuses-matrix-table td,
.focuses-matrix-table th {
  padding: 0.25rem;
  text-align: center;
}

.focuses-matrix-table svg {
  color: green;
}

.editGroupModalBody h3 {
  background-color: #fff2e1;
}

.groupRecommendationCardButtons {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  justify-content: center;
  margin: 10px;
  height: 100%;
  min-width: 60%;
}

.groupRecommendationCardButtons > button {
  width: 100%;
}

.recommendationsSelectInterventions {
  text-align: center;
  color: #f5f5f5 !important;
  border: 1px solid #00000000;
  min-width: min-content;
  max-width: max-content;
}

.recommendationsSelectInterventions.show {
  color: #616161 !important;
  border: 1px solid #616161;
  border-radius: 6px;
}

.recommendationsScheduleActive h5 {
  font-size: 0.9em !important;
}

.recommendationsSchedule,
.recommendationsScheduleActive {
  width: 100%;
  margin: 5px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
}

.recommendationsSchedule > div,
.recommendationsScheduleActive > div {
  width: 100%;
}

.recommendationsSchedule > div > p {
  background-color: #eeeeee;
  margin: 2px;
}

.recommendationsScheduleActive > div > p {
  background-color: #757575;
  color: white;
  font-size: small;
  margin: 2px;
}

.groupRecommendationInterventions > div svg {
  color: #1e88e5;
}

.recommendationsSchedule + h4 {
  font-weight: bold;
  font-size: small !important;
  color: #767676;
}

.groupRecommendationTierSelect {
  width: 7rem;
}

.groupRecommendationTierSelect > div > div {
  border-radius: 7px;
  border: 2px solid;
}

.recommendationsContainer .groupRecommendationTierSelect > div > div,
.unfinished-groups-container
  .recommended
  > .groupRecommendationTierSelect
  > div
  > div {
  background-color: #616161;
}

.yourNewGroupContainer .groupRecommendationTierSelect > div > div,
.assigmentInfo .groupRecommendationTierSelect > div > div,
.unfinished-groups-container
  .unfinished
  > .groupRecommendationTierSelect
  > div
  > div {
  background-color: #43a047;
}

.assigmentInfo .groupRecommendationTierSelect.finished > div > div {
  background-color: #00265b;
  border-color: white;
}

.intervention-group-grid-item-header.sample
  .groupRecommendationTierSelect
  > div
  > div {
  background-color: #ffab00 !important;
  border-color: #00265b;
}

.groupRecommendationTierSelect > div > div > div > div {
  color: white !important;
}

.dataFiltersSelect > div[class$="menu"] {
  z-index: 2;
}

.studentGroupCellGrade {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #525252;
  color: #ffffff;
  padding: 0 4px;
}

/*
  Groups in Progress [End]
 */

.dataContainer {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 12px;
}

.cardButtons {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: -webkit-center;
}

/*
  profile settings [Start]
 */
.profilePencilIcon {
  margin-top: 70px;
  margin-left: -30px;
  display: flex;
  height: 30px;
  width: 30px;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 50%;
  box-shadow: 1px 1px 5px rgba(32, 32, 32, 0.7);
}

.profilePencilIcon svg {
  width: 0.8em !important;
}

.profileHelpfulLinks {
  display: flex;
  flex-direction: column;
  width: 235px;
}

/*
   profile settings  [End]
 */

/*
  Recommendation settings
 */
.recommendationSettingsContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 12px;
  align-items: center;
}

.recommendIconButton {
  color: #1e88e5;
}

.recommendationSettingsContainer > .recommendIconButton {
  /*margin-left: 40px;*/
  font-size: x-large;
  text-align: center;
  align-self: center;
  cursor: pointer;
}

.recommendationSettings {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}

.recommendationSettingsFilter {
  display: flex;
  flex-direction: column;
  min-width: 200px;
}

.recommendationSettingsFilter > span {
  font-size: small;
}

.recommendationsSettingRow {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  border-radius: 0.75rem;
  text-align: center;
  font-weight: bold;
  color: #ffffff;
  background-color: #00265b;
  padding: 5px;
}

.recommendationsSettingRow span > svg {
  color: #ffffff;
}

.recommendationsSettingRow:hover {
  cursor: pointer;
}

/*

 */
.recommendationHeaderContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 1rem;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 12px;
  align-items: center;
}

.recommendationHeaderContainer > .recommendIconButton {
  margin-right: 20px;
  font-size: x-large;
  text-align: center;
  align-self: center;
}

/*
Term and conditions modal
 */

.terms-and-conditions-body {
  padding: 1rem;
}

.modal-body .terms-and-conditions-body {
  max-height: 500px;
  overflow: auto;
}

.terms-and-conditions-body p {
  text-align: justify;
}

.terms-and-conditions-body p.bold-underline {
  font-weight: 500;
  text-decoration: underline;
}

.terms-and-conditions-body > ol {
  list-style: inside decimal;
  padding: 0;
}

.terms-and-conditions-body > ol > li {
  margin-bottom: 1rem;
  font-weight: 500;
}

.terms-and-conditions-body > ol.normal {
  list-style: inside decimal;
  padding: 0;
}

.terms-and-conditions-body > ol.normal > li {
  margin-bottom: 0;
  font-weight: 300;
}

.terms-and-conditions-body > ol > ol {
  list-style: inside lower-alpha;
  padding: 0;
}

.terms-and-conditions-body > ol > ol > li {
  text-align: justify;
  margin-bottom: 1rem;
}

.terms-and-conditions-body strong {
  font-weight: 500;
}

.btnActionRight {
  display: flex;
  justify-content: flex-end;
}

.btnActionRight > button {
  margin-right: 20px;
}

/*
  Data periods modal
 */

.dataPeriodContainer {
  width: 100%;
  background-color: #f0f4f7;
  padding: 10px;
  border-radius: 8px;
}

.dataPeriodSchoolYearContainer {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: white;
  border-radius: 8px;
  border: 1px #cfd8dc solid;
}

.chatModalCloseBtn {
  display: flex;
  justify-content: flex-end;
  margin-top: -15px;
  margin-right: -10px;
}

.chatModalBody {
  background-color: #fcede7;
  border-radius: 14px 14px 12px 12px;
  padding: 20px;
}

.chatMainWindow {
  height: 350px;
  padding: 0 10px;
  margin: 0 -10px;
  overflow-y: auto;
  overflow-x: hidden;
}

.chatMainWindow::-webkit-scrollbar {
  width: 6px;
}

.chatMainWindow::-webkit-scrollbar-track {
  background: #d8d8d8;
}

.chatMainWindow::-webkit-scrollbar-thumb {
  background: #e65101;
}

.chatSimpleContainer {
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  height: 350px;
  overflow-y: auto;
}

.chatSimpleContainer::-webkit-scrollbar {
  width: 10px;
}

.chatSimpleContainer::-webkit-scrollbar-track {
  background: #d8d8d8;
}

.chatSimpleContainer::-webkit-scrollbar-thumb {
  background: #e65101;
}

.chatThreads {
  margin-bottom: 20px;
  margin-right: -10px;
  height: 380px;
  overflow-y: auto;
}

.chatThreads .chat-thread-name {
  padding: 0.5rem 1rem;
}

.chatThreads .chat-thread-name.active {
  font-weight: bold;
}

.chatThreads::-webkit-scrollbar {
  width: 6px;
}

.chatThreads::-webkit-scrollbar-track {
  background: #d8d8d8;
}

.chatThreads::-webkit-scrollbar-thumb {
  background: #e65101;
}

.chatMessageDate {
  font-size: 11px;
  color: var(--main-color);
}

.myChatRow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.otherChatRow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.myChatMessage {
  background: #dff4df;
  border-radius: 5px;
  width: 100% !important;
  padding: 4px 10px;
}

.otherChatMessage {
  background: #ededf7;
  border-radius: 5px;
  width: 100% !important;
  padding: 4px 10px;
}

.chatThreadTypeLabel {
  font-size: 11px;
  font-weight: bold;
  color: #b0bec5;
}

.chatThreadDeleteButton {
  font-size: 11px;
  font-weight: bold;
  color: #b0bec5;
  cursor: pointer;
  opacity: 0;
}

.chatThreadDeleteButton:hover {
  opacity: 1;
}

.newChatItem {
  padding: 10px;
}

.selectedNewChatItem {
  padding: 10px;
  background: #fcede7;
}

.chatCasesGroupName {
  display: flex;
  font-size: 14px;
}

.attachedFilesContainer {
  max-height: 110px;
  overflow-y: auto;
  background-color: #dee2e636;
  border: 1px #cfd8dc solid;
  border-radius: 4px;
  margin: 10px;
}

/*
  Groups In Progress Info container
 */
.groupsInProgressInfoContainer {
  background: white;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
}

.groupCardGoalTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.reportMessageAlert {
  background: rgba(255, 255, 255, 0.75);
  position: absolute;
  top: 20px;
  bottom: 20px;
  padding: 10% 15%;
  margin: 0 20px 0 -20px;
  border-radius: 12px;
}

.reportMessageAlert > div {
  text-align: center;
  background: #ffffff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.28);
}

.assessmentsSuggestionAlert {
  background: rgba(255, 255, 255, 0.75);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10% 15%;
  border-radius: 12px;
}

.assessmentsSuggestionAlert > div {
  text-align: center;
  background: #ffffff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.28);
}

.implementation-check-alert {
  background: rgba(255, 255, 255, 0.75);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.implementation-check-alert > div {
  /* border: 1px solid lightgrey; */
  padding: 1rem;
  background: white;
  border-radius: 0.25rem;
  min-width: 75%;
  margin: auto 1rem;
  z-index: 99;
}

#chartjs-tooltip {
  opacity: 1;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  border-radius: 3px;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
  pointer-events: none;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.chartjs-tooltip-key {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}

/*
  Select intervention pop-up
 */
.selectInterventionPopup {
  background: rgba(255, 255, 255, 0.75);
  position: absolute;
  z-index: 100;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 30%;
  border-radius: 12px;
}

.selectInterventionPopup > div {
  border-radius: 8px;
  background: #ffffff;
  padding: 20px;
  box-shadow: 0 0 2px 4px rgba(176, 190, 197, 0.4);
}

.groupListItem {
  background-color: #ffffff;
  margin-top: 10px;
  border-radius: 12px;
}

.groupListItemHeader {
  background-color: #616161;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 5px 10px 5px 20px;
  display: flex;
  justify-content: space-between;
  color: #ffffff;
}

.groupListItemHeaderDay {
  cursor: pointer;
  font-size: 10px;
  margin: 5px;
  background-color: #616161;
  width: 60px;
  min-height: 30px;
  border-radius: 4px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.groupListItemBody {
  display: flex;
  flex-direction: row;
}

.groupListItemBodyTier {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.groupListItemBodyData {
  padding: 10px;
  width: 100%;
}

/*
  Share data tab and modal
 */

.rosterTabShareBtn {
  display: flex;
  flex-wrap: wrap;
  height: 37px;
  max-width: 100% !important;
}

.dataShareInviteForm {
  margin: 1rem;
  align-items: center;
  max-height: 400px;
  overflow-y: auto;
}

.dataShareInviteForm.nav {
  flex-wrap: nowrap;
}

.dataShareInviteForm::-webkit-scrollbar {
  width: 10px;
}

.dataShareInviteForm::-webkit-scrollbar-track {
  background: #d8d8d8;
}

.dataShareInviteForm::-webkit-scrollbar-thumb {
  background: #e65101;
}

.dataShareInviteActionBtn {
  min-width: 80px;
  text-align: center;
}

.redIcon {
  color: #d41b32;
}

.mergeDialogButtons {
  width: 100%;
  display: flex;
  justify-content: center;
}

.mergeDialogButtons > button {
  width: 25%;
}

.flexEqualWidth {
  display: flex;
  align-items: center;
}

.flexEqualWidth > * {
  flex-grow: 1;
  flex-basis: 0;
}

.inviteTeachersModalTable {
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
  overflow: auto;
  max-height: 470px;
  min-height: 350px;
}

.inviteTeachersModalTable.nav {
  flex-wrap: nowrap;
}

.inviteTeachersModalTable::-webkit-scrollbar {
  width: 10px;
}

.inviteTeachersModalTable::-webkit-scrollbar-track {
  background: #d8d8d8;
}

.inviteTeachersModalTable::-webkit-scrollbar-thumb {
  background: #00265b;
}

.tableRowBtn {
  border: 1px #000000 solid;
  display: flex;
  background-color: #525252;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border-radius: 5px;
  width: 220px;
  padding: 5px;
  cursor: pointer;
}

.tableRowBtn:hover,
.tableRowBtn:focus {
  background-color: #444444;
}

/*
  District owner page
 */
.tableSectionHeader {
  padding: 10px 20px;
  background-color: #90a4ae;
  color: white;
  font-size: 1.5rem;
  font-weight: 700;
}

.blueHr {
  background-color: #1e88e5;
  border-width: 3px;
  margin: 30px 0;
}

.verticalSeparator {
  border-left: 1px solid;
  margin-right: 8px;
}

.customCarousel {
}

.customCarousel .carousel-caption {
  position: relative;
  margin: 0 15%;
  right: unset;
  left: unset;
  bottom: 0;
}

.customCarousel .carousel-control-prev,
.customCarousel .carousel-control-next {
  background: #8096aa;
}

.customCarousel.single-item .carousel-control-prev,
.customCarousel.single-item .carousel-control-next,
.customCarousel.single-item .carousel-indicators {
  display: none;
}

.customCarousel .carousel-indicators {
  position: relative;
  /*background: #e4eff3;*/
}

.customCarousel .carousel-indicators li {
  background-color: #002c55;
}

.nav-pills .nav-item .active {
  color: #00265b;
  background-color: #ffab00;
}

.nav-pills > .nav-item {
  display: flex;
}

.nav-pills > .nav-item > a {
  flex: 1;
}

.nav-pills > .nav-item > a > div {
  flex-grow: 1;
}

@media (min-width: 576px) {
  .modal-md {
    max-width: 500px;
  }
}

.dataTooltip {
  display: flex;
  flex-direction: column;
}

.dataTooltipHeader {
  text-align: center;
  font-weight: bold;
  color: #ffffff;
  background-color: #00265b;
  border: 1px #90a4ae solid;
  border-radius: 4px 4px 0 0;
}

.dataTooltipHeaderForAddMoreData {
  text-align: center;
  font-weight: bold;
  padding: 10px;
}

.dataTooltipBody {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 8px 8px 8px;
  background-color: #ffffff;
  border: 1px white solid;
  border-radius: 0 0 4px 4px;
  justify-content: space-evenly;
}

.dataTooltip.inline {
  flex-direction: row;
}

.dataTooltip.inline .dataTooltipHeader {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  border-radius: 4px 0 0 4px;
}

.dataTooltip.inline .dataTooltipBody {
  flex: 1;
  flex-direction: row;
  border-radius: 0 4px 4px 0;
  padding: 0;
  justify-content: start;
}

.dataTooltip.inline .dataTooltipBody > button {
  margin: 0.25rem 0 0.25rem 1rem !important;
}

.addColumnBtn {
  background-color: #ffffff;
  max-width: 17rem;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  margin: 0.5rem auto;
  border-radius: 8px;
  border: 2px #dcddde solid;
}

.addColumnBtn:hover {
  color: #00265b;
  cursor: pointer;
  background-color: #aac9d8;
  border-color: #005e89;
}

.add-new-column-popover {
  width: 32rem;
  box-shadow: 0px 0px 20px 2px #484848;
}

.add-new-column-popover > .popover-body {
  padding: 0;
}

.add-new-column-popover > .popover-body .addColumnTooltipTable {
  margin: 0 1.25rem 0 -1.25rem;
  width: calc(100% + 2.5rem);
}

.add-new-column-popover
  > .popover-body
  .addColumnTooltipTable
  > tbody
  > tr
  > td:first-child {
  text-align: unset;
  padding-left: 1.25rem;
}

.add-new-column-popover
  > .popover-body
  .addColumnTooltipTable
  > tbody
  > tr
  > td:last-child {
  padding-right: 1.25rem;
}

.add-new-column-popover > .popover-body .addColumnTooltipTable .addColumnBtn {
  margin: 0.5rem 0.25rem;
}

/*Old Tooltip table*/

.addColumnTooltipTable {
  margin: 0 0.75rem 0 -0.75rem;
  width: calc(100% + 1.5rem);
}

.addColumnTooltipTable > thead > tr > th {
  font-size: 1em;
  font-weight: bold;
  text-align: center;
  background-color: #00265b;
  color: #ffffff;
  padding: 0.5rem;
}

.addColumnTooltipTable > tbody > tr {
  background: linear-gradient(
    180deg,
    rgb(240 241 242) 50%,
    rgba(0, 212, 255, 0) 100%
  );
}

/*.addColumnTooltipTable > tbody > tr:nth-child(even) > td:first-child {*/
/*  color: #ffffff;*/
/*}*/

.addColumnTooltipTable > tbody > tr > td:first-child {
  font-size: 1em;
  text-align: center;
  padding: 0 0rem;
}

/**/

/*Add data table*/

.addMoreDataTooltipTable {
  /*margin: 0 0.75rem 0 -0.75rem;*/
  /*width: calc(100% + 1.5rem);*/
}

.addMoreDataTooltipTable > thead > tr > th {
  font-size: 1rem;
  font-weight: bold;
  /*text-align: center;*/
  background-color: #00265b;
  color: #ffffff;
  padding: .2rem 1.5rem;
}

.addMoreDataTooltipTable > tbody > tr {
  background: linear-gradient(
    180deg,
    rgb(220 221 222) 0%,
    rgba(0, 212, 255, 0) 100%
  );
}

/*.addMoreDataTooltipTable > tbody > tr:nth-child(even) > td:first-child {*/
/*  color: #ffffff;*/
/*}*/

.addMoreDataTooltipTable > tbody > tr > td {
  padding: 10px;
}

.addMoreDataTooltipTable > tbody > tr > td:first-child {
  font-weight: 600;
  line-height: normal;
}

.addMoreDataTooltipTable .addColumnBtn {
  width: 15rem;
}

/**/

.customStarIcon {
  color: #f8e71c;
  stroke: #ffab00;
  stroke-width: 20;
}

.customCircleSpan {
  background-color: #f0f4f7;
  display: inline-block;
  border-radius: 60px;
  box-shadow: 0 0 2px #888;
  padding: 0.5em;
  margin: 0 8px 0 5px;
  line-height: normal;
}

.customCircleSpan > svg {
  min-width: 1.125em;
  min-height: 1.125em;
}

.marigoldLabel {
  color: #ffab00 !important;
}

.marigoldLabel:hover {
  cursor: pointer;
  /*font-weight: bold;*/
  text-decoration: underline !important;
  color: #ffab00 !important;
}

.marigoldCircleIcon {
  color: #ffab00 !important;
  background-color: #ffffff !important;
  border-radius: 50%;
}

.studentDataTableFilters .filterSearchHeight {
  height: unset !important;
}

.studentDataTableFilters h4 {
  margin-top: 0.5rem !important;
}

.activeFbaItem {
  background-color: #cce5ff;
  border-radius: 0.25rem;
  padding: 0 5px 5px 0;
  margin-bottom: 0.25rem;
}

.dataTooltipContainer {
  padding: 0.75rem;
  margin: auto;
  background-color: #ffffff;
  border-radius: 0 0 12px 12px;
  border: 2px #dcddde solid;
  border-top: 0;
}

.dataTooltipContainer .addColumnBtn {
  max-width: 20rem;
  padding: 0.5rem;
}

.topBar {
  background-color: white;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: -10px;
  margin-bottom: 1rem;

  /*box-shadow: 0 1px 5px rgba(0,0,0,0.22);*/
  box-shadow: 0px 5px 10px 0px #dcddde;
}

/*.topBar > *:nth-child(1) {*/
/*  flex: 0.25;*/
/*}*/

.topBar > div {
  /*flex: 1;*/
  flex-basis: 40%;
  margin: 0 1rem;
  /*max-width: 40%;*/
}

/*.topBar > *:nth-child(3) {*/
/*  flex: 0.25;*/
/*  margin: 0 1rem;*/
/*  max-width: 25%;*/
/*}*/

.pageBtn {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1.5rem;
  border-radius: 0.5rem;
  color: #3a3674;
  background-color: #ffffff;
  border: 1px solid #cfd8dc;
  font-weight: bold;
}

.pageBtn:hover {
  cursor: pointer;
  color: #00c6f7;
}

.filterRow {
  background-color: #00265b;
  display: flex;
  align-items: center;
}

.filterRow > .filterRowDataPeriod {
  width: 260px;
  min-width: 260px;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
}

.filterRow > .filterRowDataPeriod > div:first-child {
  text-align: center;
  color: #ffffff;
  font-weight: bold;
}

.filterRow > .filterRowDataPeriod > div:last-child {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filterRow > .filterRowDataPeriod > div:last-child > div:first-child {
  min-width: 200px;
}

.filterRow > div:nth-of-type(2) {
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  text-align: center;
  font-weight: bold;
  color: #ffffff;
  padding: 5px;
  flex-grow: 1;
  border-left: 1px solid white;
  margin-left: 1px;
}

.filterRow span > svg {
  color: #ffffff;
}

.filterRow > div:last-child:hover {
  cursor: pointer;
}

.container-fluid {
  padding: 0 1rem;
}

.contentAreasStandardHeader {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.contentAreasStandardHeader > span {
  padding: 0 0.25rem;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: table-cell;
}

.fixedDataTableCellLayout_wrap3,
.fixedDataTableCellLayout_wrap3 > .public_fixedDataTableCell_cellContent,
.public_fixedDataTableCell_cellContent > .testLabelDropdown,
.public_fixedDataTableCell_cellContent > .assessmentLabelDropdown,
.public_fixedDataTableCell_cellContent .dropdown {
  height: 100%;
}

.public_fixedDataTableCell_cellContent .dropdown > div {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.public_fixedDataTableCell_cellContent .dropdown > div > div:first-child {
  margin: auto;
}

.public_fixedDataTableCell_cellContent {
  padding: 0;
}

.fixedDataTableRowLayout_body
  > .fixedDataTableCellGroupLayout_cellGroupWrapper:nth-child(2)
  .public_fixedDataTableCell_cellContent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.student-teacher-info > * {
  line-height: normal;
}

.behaviorsTitle {
  line-height: normal;
  max-height: 100%;
  width: 100%;
  /*text-align: center;*/
}

.behaviorsTitle > span:first-child {
  /*line-height: normal;*/
  position: relative;
  height: 1rem; /* exactly three lines */
}

.text-end {
  text-align: end;
}

.groupListTime {
  cursor: pointer;
}

.groupListTime > div {
  font-size: small;
}

.footer {
  background-color: #00265b;
  padding: 1rem 10%;
  color: white;
}

.footerSection > div:first-child {
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.footerSection > div:not(:first-child) {
  margin-bottom: 1rem;
}

.footerSection > div:not(:first-child) > a:hover {
  cursor: pointer;
  color: inherit;
  text-decoration: underline;
}

.threeStepGuideContainer {
  display: flex;
  flex-wrap: wrap;
  border-radius: 0.25rem;
  border: 1px solid #dcddde;
  background-color: #ffffff;
}

.threeStepGuideContainer > div {
  flex: 1 1 0;
  border: 1px solid #dcddde;
  display: flex;
  align-items: center;

  cursor: pointer;

  line-height: normal;
  color: #00265b;
  padding: 0.5rem 1rem;
}

.threeStepGuideContainer > div {
  opacity: 0.75;
}

.threeStepGuideContainer h3 > svg {
  color: inherit;
}

.threeStepGuideContainer > div.active {
  color: #026cff;
}

.threeStepGuideContainer > div.active,
.threeStepGuideContainer > div.completed {
  opacity: 1;
}

.threeStepGuideContainer > div h3 {
  color: inherit;
}

.threeStepGuideContainer > div > span {
  margin-right: 1rem;
}

.threeStepGuideContainer > div > span > svg {
  color: inherit;
}

/*@media (max-width: 768px) {*/
/*.topBar {*/
/*  flex-direction: column;*/
/*  align-items: unset;*/
/*  padding: 5px 15%;*/
/*}*/

/*.topBar > *:nth-child(1) {*/
/*  display: none;*/
/*  flex: unset;*/
/*}*/

/*.topBar > *:nth-child(2) {*/
/*  flex: 0.5;*/
/*  margin: unset;*/
/*  margin-bottom: 0.5rem;*/
/*  max-width: unset;*/
/*}*/

/*.topBar > *:nth-child(3) {*/
/*  flex: 0.5;*/
/*  margin: unset;*/
/*  max-width: unset;*/
/*}*/

/*.interventionGroupGoalInnerContainer > div:last-child {*/
/*  justify-content: space-evenly;*/
/*}*/
/*}*/

/*@media screen and (min-width: 769px) and (max-width: 1200px) {*/
/*.topBar > *:nth-child(1) {*/
/*  flex: 0.2;*/
/*}*/

/*.topBar > *:nth-child(2) {*/
/*  flex: 0.5;*/
/*  margin: 0 1rem;*/
/*}*/

/*.topBar > *:nth-child(3) {*/
/*  flex: 0.3;*/
/*  margin: 0 1rem;*/
/*}*/
/*}*/

@media (max-width: 1200px) {
  html {
    font-size: 14px;
  }

  .modal-body {
    padding: 1rem;
  }

  .public_fixedDataTable_bodyRow {
    padding: 0;
  }

  .assessmentLabelName > div:not(:only-child) {
    font-size: xx-small;
  }

  .assessmentLabelName,
  .assessmentLabelDate,
  .contentAreasStandardHeader {
    margin-bottom: 0 !important;
  }

  .assessmentLabelName > span {
    font-size: 0.9rem;
  }

  .studentGradeSelect > div > div {
    height: 30px;
    min-height: 30px;
  }

  .studentGradeSelect > div > div > div:first-child {
    padding: 0 8px;
  }

  .behaviorDataCell {
    margin-top: 0;
    height: 33px;
    font-size: 0.8rem;
  }

  .dataCell {
    line-height: unset;
  }

  .dataCell p.category {
    -webkit-line-clamp: 2;
  }

  .needsAreaItem, .needsAreaItemPopOver {
    margin: 0 !important;
    padding: 2px 10px;
  }

  .needsAreaItem > p, .needsAreaItemPopOver > p {
    font-size: 0.7rem;
  }
  

  .editableDataCell > div {
    width: 100% !important;
    margin: 5px !important;
    display: flex;
    align-items: center;
  }

  .editableDataCell > div > .editableDataCellInput {
    margin-top: 0;
  }

  .editableDataCell > div > .editableDataCellCategory {
    padding: 0;
    min-width: 75%;
  }

  .groupInfo .circleGroupStudents {
    display: none;
  }

  .dataTooltipBody > .whiteBtnSm {
    /*padding: 2px 15px;*/
  }

  /* Intervention Group Item */
  .interventionGroupItem .changeGroupBtn {
    display: none;
  }

  .interventionGroupItem .changeGroupIcons {
    display: flex;
  }
}

.compressed-view {
  font-size: 14px;
}

.compressed-view .public_fixedDataTable_bodyRow {
  padding: 0;
}

.compressed-view .assessmentLabelName > div:not(:only-child) {
  font-size: xx-small;
}

.compressed-view .assessmentLabelName,
.compressed-view .assessmentLabelDate,
.compressed-view .contentAreasStandardHeader {
  margin-bottom: 0 !important;
}

.compressed-view .assessmentLabelName > span {
  font-size: 0.9rem;
}

.compressed-view .studentGradeSelect > div > div {
  height: 30px;
  min-height: 30px;
}

.compressed-view .studentGradeSelect > div > div > div:first-child {
  padding: 0 8px;
}

.compressed-view .behaviorDataCell {
  margin-top: 0;
  height: 33px;
  font-size: 0.8rem;
}

.compressed-view .dataCell {
  line-height: unset;
}

.compressed-view .dataCell > p {
  -webkit-line-clamp: 2;
}

.compressed-view .needsAreaItem, .compressed-view .needsAreaItemPopOver {
  margin: 0 !important;
  padding: 2px 5px;
}

.compressed-view .needsAreaItem > p, .compressed-view .needsAreaItemPopOver > p {
  font-size: 0.9rem;
}

.compressed-view .editableDataCell > div {
  width: 100% !important;
  margin: 5px !important;
  display: flex;
  align-items: center;
}

.compressed-view .editableDataCell > div > .editableDataCellInput {
  margin-top: 0;
}

.compressed-view .editableDataCell > div > .editableDataCellCategory {
  padding: 0;
  min-width: 75%;
}

/*
 * Parent page
 */

.parentContainer .toolsRowContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

#inviteParentForm {
  flex-direction: column;
  width: 65%;
  margin: auto;
}

#inviteParentForm > div {
  padding: 10px;
}

#inviteParentForm > div label {
  margin-bottom: 15px;
}

.parentInviteFormContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
}

.parentInviteFormContainer label {
  width: 100%;
}

.tutorialsAccordion {
}

.tutorialsAccordion .card {
  margin-bottom: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.tutorialsAccordion .card-header {
  background-color: #00265b;
  font-size: 1.25rem;
  line-height: normal;
}

.tutorialsAccordion .card-header:hover {
  cursor: pointer;
}

.tutorialsAccordion .card-header svg {
  color: #ffffff;
  width: 0.625em;
}

.tutorialsAccordion .card-body {
  color: unset;
}

.localeBtn {
  color: #ffffff;
  font-weight: bold;
}

.localeBtn:hover {
  cursor: pointer;
  color: #00c7f8;
}

.hiddenRequiredInput {
  opacity: 0;
  height: 0;
  width: 100%;
  overflow: hidden;
  cursor: default;
  position: absolute;
}

.meetingDetails {
  display: flex;
  justify-content: space-between;
}

.meetingDetails > div {
  flex: 1 50%;
  flex-basis: 50%;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.measure-for-all-popover {
  max-width: 600px;
}

.rc-indicators-popover {
  max-width: 600px;
}

.observations-popover {
  max-width: 600px;
}

.brs-popover {
  max-width: 800px;
  /*max-height: 600px;*/
}

.group-workspaces {
  position: inherit;
  display: flex;
  flex-wrap: wrap;
  margin: -0.5rem;
}

.group-workspaces > div {
  flex-basis: calc(50% - 1rem);
  min-width: calc(50% - 1rem);
  flex-grow: 1;
  margin: 0.5rem;
}

.intervention-plan-chart {
  /*flex-grow: 1;*/
  /*min-height: 400px;*/
}

.group-workspaces .purpleModalHeader {
  padding: 5px 10px;
  border-radius: 10px 10px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.group-workspaces .purpleModalHeader span > svg {
  color: white;
}

.group-workspaces .purpleModalHeader span > svg:hover {
  cursor: pointer;
  color: #00c7f8;
}

.intervention-plan-active {
  max-height: 32rem;
  overflow-y: auto;
  -ms-overflow-y: auto;
}

.group-workspaces .workspace-body {
  padding: 0.5rem;
  border: 1px solid #d3d3d3;
  border-top: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: column;
  max-height: 32rem;
}

.group-workspaces .workspace-body > div {
  flex: 0 0 auto;
}

.group-workspaces .workspace-body .workspace-content-container {
  flex: 0 1 auto;
  overflow-y: auto;
  -ms-overflow-y: auto;
}

.lesson-notes-upsert-form {
  padding: 0.5rem;
  border: 1px solid #d3d3d3;
  border-radius: 0.5rem;
  background: linear-gradient(#fdfdfd, #eaeaea);
}

.lesson-notes-item {
  padding: 0.5rem;
  border: 1px solid #d3d3d3;
  border-radius: 0.5rem;
  background: linear-gradient(#fdfdfd, #eaeaea);
}

.lesson-notes-item + .lesson-notes-item {
  margin-top: 1rem;
}

.intervention-plan-list .intervention-plan-item:last-child {
  border-radius: 0 0 10px 10px;
}

.students-focuses-modal .modal-dialog {
  max-width: 1000px;
}

.students-focuses-modal .modal-title {
  width: 100%;
  text-align: center;
}

.student-focuses-table {
  margin: auto;
  border: 1px solid #d6d6d6 !important;
}

.student-focuses-table .first-column-header,
.student-focuses-table .second-column-header {
  background-color: #ebebeb;
  font-weight: bold;
}

.student-focuses-table .public_fixedDataTableCell_cellContent,
.student-focuses-table .public_fixedDataTableCell_cellContent {
  cursor: default;
}

.student-focuses-table .first-column-cell {
  background-color: #ffffff;
}

.student-focuses-table .second-column-cell,
.student-focuses-table .fixedDataTableCellGroupLayout_cellGroupWrapper {
  background-color: #f8f8f8;
  color: #455a64;
}

.student-focuses-table .first-column-cell button {
  width: 200px;
  border: none;
  background-color: #00265b;
  border-radius: 4px !important;
  padding: 5px 15px !important;
  color: #ffffff;
  font-weight: 600 !important;
}

.student-focuses-table .first-column-header,
.student-focuses-table .first-column-cell,
.student-focuses-table .second-column-header,
.student-focuses-table .second-column-cell {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-right: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
}

.number-in-circle {
  width: 20px;
  line-height: 20px;
  background: #000000;
  color: #ffffff;
  border-radius: 50%;
  font-weight: bold;
}

.student-focuses-table .first-column-cell .number-in-circle {
  width: 35px;
  line-height: 35px;
  background: limegreen;
}

.student-focuses-table .second-column-cell .interventionList {
  padding: 10px;
  max-height: 100% !important;
  text-align: start;
}

.student-focuses-table .focus-info {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.student-focuses-table .focus-info hr {
  width: 100%;
}

.focus-info.nav {
  flex-wrap: nowrap;
}

.focus-info::-webkit-scrollbar {
  width: 10px;
}

.focus-info::-webkit-scrollbar-track {
  background: #f0f4f7;
}

.focus-info::-webkit-scrollbar-thumb {
  background: #00265b;
}

.color-limegreen,
.color-limegreen svg {
  color: limegreen !important;
}

.flex-1 {
  flex: 1;
}

.group-interventions-popover .purpleModalHeader {
  text-align: center;
  padding: 6px;
  font-size: 1rem;
}

.intervention-plan-list {
  width: 30vw;
}

.intervention-plan-item {
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  background: linear-gradient(#fdfdfd, #eaeaea);
  border: 1px solid lightgray;
}

.sequence-list-row > span {
  flex: 14;
}

.sequence-list-row > span:first-child {
  flex: 1;
}

.intervention-plan-modal .modal-dialog {
  max-width: 75vw;
}

.entry-actions {
  margin: -0.5rem;
}

.entry-actions > span {
  cursor: pointer;
  margin: 0.5rem;
}

.entry-actions > .edit:hover > svg {
  color: #00265b;
}

.entry-actions > .delete:hover > svg {
  color: red;
}

/* spelling test*/
.spelling-test-group-item {
  display: flex;
  flex-direction: column;
  border: 1px solid #484848;
  border-radius: 0.5rem;
  padding: 0.5rem;
  background-color: #f0f4f7;
}

.spelling-test-group-item > hr {
  width: 100%;
  border-color: #484848;
  margin: 0.5rem 0;
}

.spelling-test-group-item .grade {
  margin-left: -0.5rem;
  padding: 0 1rem;
  font-size: 1.75rem;
  font-weight: bold;
}

.spelling-test-upsert-test-form-table {
}

.spelling-test-group-statistics > thead > tr > th,
.spelling-test-group-statistics > tfoot > tr > td,
.spelling-test-upsert-test-form-table > thead > tr > th,
.spelling-test-upsert-test-form-table > tfoot > tr > td {
  text-align: center;
  vertical-align: middle;
  font-weight: normal;
  font-size: medium;
  border: 2px solid black;
}

.spelling-test-upsert-test-form-table .form-control {
  padding: 0.125rem;
  height: unset;
  text-align: center;
}

.spelling-test-group-statistics > tbody,
.spelling-test-upsert-test-form-table > tbody {
  border: 2px solid black;
}

.spelling-test-upsert-test-form-table > tbody > tr:nth-of-type(1) {
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}

.spelling-test-upsert-test-form-table > tbody > tr:nth-of-type(n + 2) > td {
  text-align: center;
}

.spelling-test-upsert-test-form-table > tbody > tr > td:nth-of-type(2n) {
  border-left: 2px solid black;
}

.spelling-test-group-statistics > tbody > tr > td {
  text-align: center;
  border-left: 2px solid black;
}

.spelling-test-upsert-test-form-table > tbody .input-group {
  flex-wrap: nowrap;
}

.source-part {
  background-color: #ffe598 !important;
}

.correct-part {
  background-color: #c5e0b3 !important;
}

.wrong-part {
  background-color: #f7caac !important;
}

.empty-part {
  background-color: #cfe2f3 !important;
}

.spelling-test-item {
  display: flex;
  flex-direction: column;
  border: 1px solid #484848;
  border-radius: 0.5rem;
  padding: 0.5rem;
  background-color: #f0f4f7;
}

.spelling-test-item > hr {
  width: 100%;
  border-color: #484848;
  margin: 0.5rem 0;
}

.spelling-test-full-word-actions {
}

.spelling-test-full-word-actions > .correct > svg {
  color: limegreen;
  opacity: 0.5;
}

.spelling-test-full-word-actions > .wrong > svg {
  color: #ff0000;
  opacity: 0.5;
}

.spelling-test-full-word-actions > .active > svg {
  opacity: 1;
}

.spelling-test-full-word-actions > .correct:hover > svg,
.spelling-test-full-word-actions > .wrong:hover > svg {
  cursor: pointer;
  opacity: 1;
}

.removeIcon:hover {
  cursor: pointer;
}

.removeIcon > svg {
  color: #ff0000 !important;
  opacity: 0.75;
}

.removeIcon > svg:hover {
  color: red !important;
  opacity: 1;
}

.flex-wrap-container {
  display: flex;
  flex-wrap: wrap;
  margin: -0.25rem;
}

.flex-wrap-container > * {
  margin: 0.25rem;
}

.react-bootstrap-table > .not-fixed {
  table-layout: unset !important;
}

.groups-tool-bar {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: -0.5rem;
  flex-wrap: wrap;
}

.groups-tool-bar > * {
  margin: 0.5rem;
}

.groups-tool-bar > .recommendations-checkbox {
  padding: 5px 10px;
  background-color: white;
  border: 1px solid #cccccc;
  border-radius: 4px;
  font-weight: 500;
}

.groups-tool-bar .group-search {
  width: 25%;
  min-width: 15rem;
}

.sorted-groups-container {
  display: flex;
  flex-wrap: wrap;
  margin: -1rem;
}

.sorted-groups-container > div {
  margin: 1rem;
}

.academic-stats {
  display: flex;
  justify-content: space-around;
  color: white;
  margin: 40px;
}

.academic-stats-btn {
  background: #00265b;
  max-width: 18%;
  flex-grow: 1;
  text-align: center;
  padding: 20px;
  border-radius: 8px;
  text-transform: uppercase;
}

.academic-stats-btn > h1 {
  color: white;
}

.react-bootstrap-table table {
  overflow: auto;
  table-layout: auto;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.-m-2 {
  margin: -0.5rem;
}

.class-wide-need-list,
.class-wide-need-list ul {
  list-style: none;
  padding-left: 1.5rem;
  margin-bottom: 0;
}

.class-wide-need-list li:before {
  content: "—";
  position: relative;
  left: -0.5rem;
}

.benchmark-area-minimal {
  display: flex;
}

.benchmark-area-minimal .viewBenchmarksTable {
  table-layout: fixed;
  margin-top: 0;
}

.benchmark-area-minimal > .row {
  min-width: 25%;
  flex-direction: column;
  margin-right: 0;
}

.class-wide-need-container .only-for-pdf {
  display: none !important;
}

.existing-students-container {
  margin: -0.25rem;
  display: flex;
  flex-wrap: wrap;
  max-height: 20rem;
  overflow-y: auto;
}

.existing-students-container > div {
  padding: 0.5rem;
  border: 1px #cfd8dc solid;
  border-radius: 0.5rem;
  margin: 0.25rem;
  flex: 1 1 30%;
}

.existing-students-container > div:hover {
  cursor: pointer;
  background-color: #f7f4fc;
}

.sticky-tr {
  background: white;
}

.sticky-tr > * {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background: inherit;
  z-index: 1;
  outline: 1px solid #dee2e6;
  border: none;
  outline-offset: -1px;
}

.equity-benchmark-table-max-height-wrapper > div {
  max-height: 675px;
}

.equity-benchmark-table-max-height-wrapper
  tbody:not(.equity-benchmark-table-all-students-row) {
  opacity: 0.5;
}

.equity-benchmark-table-max-height-wrapper tbody:hover {
  opacity: 1;
}

.z-index-1070 {
  z-index: 1070 !important;
}

.z-index-1075 {
  z-index: 1075 !important;
}

.intervention-stats-container {
  display: flex;
  margin: -0.25rem;
}

.intervention-stats-container > * {
  margin: 0.25rem;
}

.intervention-stats-container > *:first-child {
  flex: 1;
}

.intervention-stats-container > *:last-child {
  flex: 2;
}

.intervention-stats-container .list-group-item {
  padding: 0.25rem 0.5rem;
}

.fidelity-value-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0 0.5rem;
  border-radius: 0.25rem;
  background-color: #dee2e6;
  min-width: 3.575rem;
}

.checklistItemHeader {
  background-color: rgb(0, 39, 91);
  padding: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
  border-radius: 4px;
}

.checklistItemHeader span {
  font-size: 1.25rem;
  font-weight: bold;
  color: #fff;
}

.github-picker {
  margin-right: -17px;
}

.bg-gray {
  background-color: #f5f3f3;
}
.min-vh-83 {
  min-height: 83vh !important;
}

.container-box {
  min-height: calc(100vh - 63px);
}

input[type="checkbox"] {
  z-index: 9999;
}

.bdr {
  border-radius: 5px;
  border-spacing: 0px;
  border-collapse: separate;
}

select.removeArrow {

  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

.rc-time-picker-panel-combobox {
  display: none;
}

.rc-time-picker-panel-inner {
  border: 0;
  background: transparent;
  box-shadow: none;
}
.rc-time-picker-panel-input-wrap {
  box-sizing: border-box;
  position: relative;
  padding: 6px;
  border-bottom: none;
}
.rc-time-picker-panel-input {
  margin: 0;
  padding: 0;
  width: 100%;
  cursor: auto;
  line-height: 1.5;
  outline: 0;
  border: 1px solid transparent;
  box-shadow: none;
}

.rc-time-picker-panel-input-invalid {
  border-color: red;
  border: 0;
  line-height: 1.8;
}

.h-31 {
  height: 31px;
}

.interventionAvg {
  width: calc(100% + 15px);
  display: flex;
  margin-right: -15px;
  align-items: center;
}

.interventionAvgArrow {
  position: relative;
  right: 6px;
  bottom: 2px;
}

.font-size-teacher {
  font-size: 10px;
  margin-left: 1px;
}

.lh-15 {
  line-height: 15px;
}

.margin-class {
  margin-left: -8px;
}



.group-btn {
  width: 100%;
}

.blueStrip {
  background-color: blue !important;
  margin: 0;
}

.parentBlueStrip {
  height: 100%;
}

.w-25 {
  width: 25% !important;
}

.w-75 {
  width: 75% !important;
}

.w-90 {
  width: 90% !important;
}

.w-40 {
  width: 42% !important;
}

.isGradeInvalid, .isTeacherInvalid, .isSchoolInvalid {
  border: 1px solid #853422;
  padding: 10px 0px 10px 0px;
  border-radius: 10px;
}
.accordian.card-header {
  background-color: #b7d5cc;
}

button.btn:hover {
  color: #ffffff;
}

.smallAlertModal > div {
  background: #ffffff;
  padding: 20px;
  width: 400px;
  height: fit-content;
  margin-top: 20%;
  box-shadow: 0 0 2px 4px rgb(176 190 197 / 40%);
  position: fixed;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.navigation-confirm .modal-dialog {
  max-width: 400px;
}

button.btn:hover {
  color: #ffffff;
}

.navigation-confirm .modal-dialog {
  max-width: 400px;
}

.msgClass {
  background-color: #dbeefb;
  padding: 15px;
  border-radius: 10px;
}
.intervention-box{
  padding-top:5px;
}

.intervention-box.intervention-group-grid-item-body h5.section:first-child{
  margin-top:0 ;
}

.intervention-box .assigmentCollapseHeader {
  margin: 0px;
}

.finished-group-additional-educator {
  padding: 0.25rem 0.5rem;
}


#user_idle-modal.modal-dialog {
  max-width: 400px;
}

.badge .close {
		margin-left: .35rem !important;
		color: inherit !important;
		font-size: 100% !important;
		text-shadow: 0 1px 0 rgba(#000, .5) !important;
	}
.badge-parent {
  font-size: 100% !important;
}
.secondryGoalTable tbody tr:nth-child(even) {
  background-color: #fff;
}
.blueText {
  color: #3a8cff;
}
.collapse-container h3 {
  font-size: 1rem !important;
}

.table-mb {
  margin-bottom: 5px !important;
}
.headingFont strong {
  font-size: 15px;
}
.headingFont small {
  font-size: 78%;
}
.screenerTable th, .screenerTable td {
  padding: .5rem;
}
.redText {
  color: red;
}
.innerTable td {
  border-left: 0;
  border-top: 0;
}

.innerTable tr:last-child td {
  border-bottom: 0 !important;
}

.innerTable tr {
  border-left: 0 !important;
  border-right: 0 !important;
}

table.table-bordered.innerTable.table {
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
}
.innerTable td:last-child {border-right: 0;}

.goalTable tbody tr.tr-border{
  border-right: 0;
    border-bottom: 0;
    border-top: 0;
}
.goalTable tbody tr .td-border-t{
  /* border-top:0; */
  height: 71px;
}

.Blink {
  animation: blinker .2s cubic-bezier(.5, 0, 1, 1) infinite alternate;  
}
@keyframes blinker {  
  from { opacity: 1; }
  to { opacity: 0; }
}
.circle {
  display: flex;
  width: 23px;
  height: 23px;
  background-color: #00265b;
  border-radius: 50%;
  align-items: center;
  padding: 5px;
  cursor: pointer;
}
.circle svg {
  color: #fff;
  font-size: 13px;
  font-weight: bold;
}
.w-25px {
  width: 25px;
}
.initialFade {
  opacity: 0;
  transition: opacity 0.5s;
}

.initialFade.in {
  opacity: 1;
}

.list-order-swither-checkbox .custom-control-label:before{
  background-color:rgb(255, 171, 0);
  cursor: pointer;
}
.list-order-swither-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-color:rgb(255, 171, 0) ; /* Change the background color of the radio button when it is checked */
  border: 2px solid blue; /* Add a border if you prefer */
  cursor: pointer;
}
.list-order-swither-checkbox .custom-control-input:not(:checked) ~ .custom-control-label::after {
  background-color: blue !important; /* Change the background color of the radio button when it is checked */
  border: 2px solid rgb(255, 171, 0); /* Add a border if you prefer */
  cursor: pointer;
}

#list-order-switch {
  cursor: pointer;
}
.show-class-wide-modal-width .modal-sm {
  max-width: 500px;
}
.circle-transparent {
  display: -ms-flexbox;
  display: flex;
  width: 23px;
  height: 23px;
  background-color: transparent;
  border-radius: 50%;
  -ms-flex-align: center;
  align-items: center;
  padding: 5px;
}

.w-170 {
  width: 170px;
}

.only-students input[type="radio"] {
  cursor: pointer;
}

.only-students label {
  cursor: pointer;
}

.all-students input[type="radio"] {
  cursor: pointer;
}

.all-students label {
  cursor: pointer;
}

.header-content {
  width: auto;
  margin: 0 auto;
  font-size: 18px;
}

.below-header-content-one {
  width: 120px;
    margin: 0 auto;
}

.below-header-content-two {
  width: 300px;
    margin: 0 auto;
}

.multiline-break {
  max-width: 150px;
  word-wrap: break-word;
}
.filterRow > .emptyDataPeriod {
  padding: 0.5rem;
  display: flex;
  width: inherit;
  flex-direction: row;
  max-width: inherit;
  flex: 0 0 auto;
  justify-content: space-between;
}

.filterRow > .emptyDataPeriod > div:first-child {
  text-align: center;
  color: #ffffff;
  font-weight: bold;
  padding-right: 15px;
}

.filterRow > .emptyDataPeriod > div:last-child {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filterRow > .emptyDataPeriod .pointer {
  padding-left: 15px;
}

.filterRow > .emptyDataPeriod > div:last-child > div:first-child {
  min-width: 200px;
}
.reset-border {
  border-left-style: solid;
  padding-left: 6px;
}
.popOverClass {
  max-width: 700px;
}
.dangerBtnSm {
  border: none;
  background-color: #f8d7da;
  border-radius: 4px !important;
  padding: 5px 15px !important;
  color: #721c24;
  font-weight: 600 !important;
  font-size: 0.78rem;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  border-color: #f5c6cb;
}

.dangerBtnSm:hover
{
  background-color: #721c24;
  transition: background-color 0.4s;
  outline: none;
  color: #f8d7da;
}

.pdfFontFamily {
  font-family: "Arial";
}
.marigoldBtnSmFilter {
  border: none;
  background-color: rgb(255, 171, 0);
  border-radius: 4px !important;
  padding: 5px 12px !important;
  color: #00265b;
  font-weight: 600 !important;
  font-size: 0.78rem;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
}
.noOverflow .react-bootstrap-table {
    overflow-x: hidden;
}
.custom-table-class {
  width: 100%;
    table-layout: fixed;
}
.redBtnSmFilter {
  border: 1px solid #d41b32;
  background-color: #ff172c;
  border-radius: 4px !important;
  padding: 5px 12px !important;
  color: #d41b32;
  font-weight: 600 !important;
  font-size: 0.78rem;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
}

#recommendations-setting-popover.popover {
  max-width: 545px;
}

.benchmark-modal-pdf-class::-webkit-scrollbar {
  display: none;
}
@media screen and (min-width: 1320px){
  .tutorialsAccordion.accordion img{
    margin-left: 42px;
  }
  /* .tutorialsAccordion.accordion ul{
    margin-left: -42px;
  } */
}
.demographicsAccordion {
  background-color: #f4f4f4 !important;
  border: 1px solid rgb(255, 171, 0);
  padding: 5px 10px;
  margin-bottom: 20px;
  border-radius: 4px;
}
.demographicsAccordion h3 {
  font-size: 17px !important;  
}

.notPlannedItemCheckbox .custom-control-input:checked~.custom-control-label::before {
  background-color: gray;
  border: gray;
}

.observedItemCheckbox .custom-control-input, .notPlannedItemCheckbox .custom-control-input {
  width: 1.62rem;
  height: 1.62rem;
}

.stepHeader {
  background:#474f57f2;
  color: white;
  border-radius: 4px;
}

.narrowHeaderPadding {
  padding: 0px 0px 10px 20px;
}
.font-weight-font-size{
  font-weight: 500;
  font-size: 15px;
}

/* Container to position icon */
.input-container {
  position: relative;
}

/* Style for input to add padding for the icon */
.input-with-icon {
  padding-right: 2.5rem; /* Adjust padding to accommodate icon */
}

/* Style for the icon */
.icon-eye {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}
.goalSetOn {
  width: 120px;
  border-left: 1px solid #dee2e6 !important;
}

.react-datepicker__close-icon {
  top: 52%;
}
.customDatePickerWidth, 
.customDatePickerWidth div.react-datepicker-wrapper, 
.customDatePickerWidth div div.react-datepicker__input-container,
.customDatePickerWidth div div.react-datepicker__input-container input {
   width: 100% !important;
}