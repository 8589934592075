/* Modal Overrides */

body {
  font-family: "Roboto", sans-serif;
}

.container-fluid {
  padding-left: 40px;
  padding-right: 40px;
}

.close {
  font-weight: 300;
}

.modal {
  border-radius: 12px;
}

.modal-content {
  border-radius: 14px 14px 12px 12px;
  color: #455a64;
}

.modal-body {
  padding: 1.2rem 2rem;
}

.popover {
  max-width: 400px;
}

.modal-header {
  border-radius: 12px 12px 0 0;
  align-items: center;
  padding: 1rem 2.2rem;
}

.modal-footer {
  border-top: none;
}

.modal-header .close {
  padding: 0;
  margin: 0;
  color: #ffffff;
}

.modal-title {
  margin: 0 auto;
  font-weight: 500;
}

.modal-title svg {
  margin-left: 7px;
  margin-bottom: 4px;
}

.jumbotron {
  background-color: #ffffff;
  padding: 20px;
}

/* Button Overrides */

.btn {
  all: unset;
}

.btn-primary:link {
  all: unset;
}

.btn-primary:visited {
  all: unset;
}

.btn-primary:hover {
  all: unset;
}

.btn-primary:active {
  all: unset;
}

.btn-link {
  color: #2a74d1;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  all: unset;
}

label {
  margin: 0;
}

.table td {
  vertical-align: middle;
}

.table td p,
.table td h4 {
  padding: 0 !important;
  margin: 0 !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Form Overrides */

.form-control {
  border-radius: 4px;
  border: 1px #cfd8dc solid;
  padding: 8px;
  color: #455a64;
  font-size: 0.875rem;
  outline: none;
  box-shadow: none;
}

.form-control::placeholder {
  font-weight: 300;
  color: #c5c5c5;
}

.form-control:focus {
  box-shadow: 0 0 5px rgba(46, 116, 209, 0.6);
  transition: box-shadow 0.4s;
}

.nav-pills {
  background-color: rgba(172, 187, 195, 0.25);
  border-radius: 8px;
  width: 100%;
  margin: auto;
  box-shadow: inset 0 0 2px rgba(172, 187, 195, 1);
  margin-bottom: 10px;
}

.nav-pills .nav-item {
  flex: 1;
}

.nav-pills .nav-item a {
  height: 60px;
  padding: 0;
  color: #455a64;
  font-size: 1rem;
}

.nav-pills .nav-item div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  height: 100%;
}

.nav-pills .nav-item .active {
  background-color: #2a74d1;
  font-weight: 600;
}

.nav-pills .nav-item:first-of-type a {
  border-radius: 8px 0 0 8px;
}

.nav-pills .nav-item:nth-of-type(2) a {
  border-radius: 0;
}

.nav-pills .nav-item:last-of-type a {
  border-radius: 0 8px 8px 0;
}

.onboardingSecondStepTab .nav-link {
  color: #00265b;
  padding: 5px 10px;
  border-radius: 4px;
}

.onboardingSecondStepTab .nav-link.active {
  font-weight: 700;
  background-color: #f4ae3d;
}

.onboardingSecondStepTab.nav-item {
  display: inline-block;
}

.teacherDetailsTab .nav-link {
  color: #00265b;
  padding: 5px 10px;
  border-radius: 4px;
}

.teacherDetailsTab .nav-link.active {
  font-weight: 700;
  background-color: rgba(103, 58, 183, 0.1);
}

.teacherDetailsTab.nav-item {
  display: inline-block;
}

.progress {
  border-radius: 8px;
}

.bg-success {
  background-color: #8bc34a !important;
}

.font-weight-semibold {
  font-weight: 600;
}

.font-weight-normal {
  font-weight: 300 !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.dropdown-toggle {
  all: unset;
}

.dropdown-toggle:focus {
  outline: none;
}

.dropdown-menu {
  top: 2px !important;
  left: -11px !important;
  font-size: 0.8rem;
  padding: 0;
}

.dropdown-item {
  padding: 5px 10px;
  font-weight: 600;
  color: #484848;
}

.dropdown-item:active {
  background-color: #2a74d1;
}

.dropdown-item:hover {
  background-color: #f0f4f7;
  color: #455a64;
  transition: background-color 0.4s, color 0.4s;
}

.card {
  border-radius: 6px !important;
  margin-bottom: 20px;
}

.card-header {
  background-color: #455a64;
  color: #ffffff;
  font-weight: 600;
  padding: 5px 10px;
  border-radius: 6px 6px 0 0 !important;
}

.card-header svg {
  color: #ffffff;
}

.card-body {
  background-color: #f5f5f5;
  color: #455a64;
  padding: 10px;
  border-radius: 0 0 6px 6px !important;
}

#newInviteForm .form-row {
  margin-top: 10px !important;
}

#newInviteForm .form-row:first-of-type {
  margin-top: 0 !important;
}

.form-group label {
  color: #484848 !important;
}

.form-group select {
  height: 40px;
}

.navbar-brand {
  color: #ffffff !important;
  padding: 0 !important;
  font-size: 1.6rem;
  margin-right: 6rem;
}

#readycoach-header .nav-link {
  text-transform: uppercase !important;
  color: #ffffff !important;
  font-weight: 700;
  padding: 0 1rem;
  font-size: 0.8rem;
  letter-spacing: 0.15rem;
}

#readycoach-header .nav-link:hover {
  color: #00c6f7 !important;
  transition: color 0.4s;
}

#readycoach-header .nav-link a {
  all: unset;
}

.input-group-prepend span {
  padding: 0 15px;
  background-color: #ffffff;
  color: #484848;
  border: 1px #bec9cf solid;
  font-weight: 600;
}

.input-group-append span {
  padding: 0 15px;
  background-color: #ffffff;
  border-left: none;
}

.input-group-append span svg {
  color: #b0bec5;
}

#newStudentForm .input-group-prepend + input {
  border-right: none;
}

#teacherInfoForm .input-group {
  margin-bottom: 20px;
}

.modal-header svg {
  color: #ffffff;
  font-size: 0.7rem;
}

.modal-header h5 {
  color: #ffffff;
}

#editStudentDetails {
  padding-bottom: 5px;
}

#editStudentDetails .form-label {
  font-size: 0.75rem;
  font-weight: 600;
}

#editStudentDetails .form-group {
  margin-bottom: 5px;
}

/* .react-bootstrap-table-pagination {
  display: flex;
  align-items: center;
} */

.react-bootstrap-table-pagination ul {
  margin-bottom: 0;
}

#pageDropDown {
  background-color: #ffffff;
  padding: 0 5px;
  border: 1px #f0f4f7 solid;
  border-radius: 4px;
  color: #484848;
}

#pageDropDown:hover {
  color: #484848;
}

.page-item.active .page-link {
  background-color: #2a74d1;
  border-color: #2a74d1;
}

.dropdown-item input[type="radio"] {
  top: -2px;
}

.data_cell__value-container {
  padding: 0 !important;
  text-align: center;
}

.data_cell__control {
  width: 60px;
  height: 20px !important;
}

.data_cell__indicators {
  display: none !important;
}

.data_cell__menu {
  width: 200px !important;
}

@media only screen and (max-width: 600px) {
  #newInviteForm .col {
    margin-bottom: 0;
  }

  .modal-body {
    padding: 1.2rem;
  }

  #readycoach-header .nav-link {
    display: none;
    color: #455a64 !important;
    font-weight: 700;
    padding: 0 !important;
  }

  #readycoach-header .nav-link:hover {
    color: #ffffff !important;
    transition: color 0.4s;
  }

  #newStudentForm .form-row input {
    margin-bottom: 10px !important;
  }

  #newStudentForm .form-row h4 {
    text-align: center !important;
    padding-bottom: 10px;
  }

  .onboardingSecondStepTab .nav-link {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 800px) {
  .nav-pills .nav-item a {
    height: 65px;
    font-size: 0.8rem;
  }

  .navbar-brand {
    margin-right: 1rem;
  }

  .modal-lg {
    max-width: 100%;
    margin: 60px 5px !important;
  }

  .container {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }

  .mb_mt-1 {
    margin-top: 10px;
  }
}

@media only screen and (min-width: 800px) {
  .modal-lg {
    max-width: 800px;
    margin: 60px auto;
  }
}

@media only screen and (min-width: 1000px) {
  .modal-lg {
    max-width: 1000px;
  }
}

@media only screen and (max-width: 1199px) {
  .container {
    max-width: 100% !important;
  }
}

/* Table Overrides */

.public_fixedDataTable_main {
  border-color: #ffffff00 !important;
}

.public_fixedDataTableCell_main {
  border-color: #ffffff00 !important;
}

.fixedDataTableCellLayout_main {
  border-right-width: 0 !important;
  margin-right: 5px;
}

.public_fixedDataTableCell_cellContent {
  padding: 8px 0;
  text-align: center;
  cursor: pointer;
}

.fixedDataTableCellLayout_wrap1 {
  margin: auto;
}

.public_fixedDataTable_header,
.public_fixedDataTable_scrollbarSpacer,
.public_fixedDataTable_header .public_fixedDataTableCell_main {
  background-color: white !important;
  background-image: none !important;
}

.public_fixedDataTableCell_main {
  display: flex !important;
}

.order-4 {
  display: none; /*does not show the sorting icon for a table column if the sorting is performed not by this column*/
}
